/*
 * Translation Keys
 * @return enum
 */

export enum I18N {
  // ACCOUNT
  ACCOUNT_CREATE = 'ACCOUNT.CREATE',
  ACCOUNT_IS_ACCOUNT = 'ACCOUNT.IS_ACCOUNT',
  // MENU
  MENU_HOME = 'MENU.HOME',
  MENU_SUPPLY = 'MENU.SUPPLY',
  MENU_REQUEST = 'MENU.REQUEST',
  MENU_REGISTER = 'MENU.REGISTER',
  MENU_EXCHANGES = 'MENU.EXCHANGES',
  MENU_IN_PROGRESS = 'MENU.IN_PROGRESS',
  MENU_COMPLETED = 'MENU.COMPLETED',
  MENU_SETTING = 'MENU.SETTING',
  MENU_MATERIALS = 'MENU.MATERIALS',
  MENU_LOCATIONS = 'MENU.LOCATIONS',
  MENU_CO2_EMISSION = 'MENU.CO2_EMISSION',
  MENU_TRANSPORT = 'MENU.TRANSPORT',
  MENU_MARKETPLACE = 'MENU.MARKETPLACE',
  MENU_OFFER = 'MENU.OFFER',
  MENU_MY_LISTING = 'MENU.MY_LISTING',
  MENU_REPORTING = 'MENU.REPORTING',
  MENU_DATA_EXTRACTION = 'MENU.DATA_EXTRACTION',
  MENU_MY_PROJECTS = 'MENU.MY_PROJECTS',
  MENU_MY_STORAGES = 'MENU.MY_STORAGES',
  MENU_DECLARATIONS = 'MENU.DECLARATIONS',
  MENU_PROJECTS = 'MENU.PROJECTS',
  MENU_COMPANIES = 'MENU.COMPANIES',
  MENU_COMPANY = 'MENU.COMPANY',
  MENU_EMISSIONS_CALCULATOR = 'MENU.EMISSIONS_CALCULATOR',
  MENU_USERS = 'MENU.USERS',
  MENU_CUSTOMERS = 'MENU.CUSTOMERS',
  MENU_RESOURCES = 'MENU.RESOURCES',
  // BUTTONS
  BUTTONS_LOGIN = 'BUTTONS.LOGIN',
  BUTTONS_LOGOUT = 'BUTTONS.LOGOUT',
  BUTTONS_I_AM_NEW = 'BUTTONS.I_AM_NEW',
  BUTTONS_SAVE = 'BUTTONS.SAVE',
  BUTTONS_REGISTER = 'BUTTONS.REGISTER',
  BUTTONS_RESET = 'BUTTONS.RESET',
  BUTTONS_CREATE = 'BUTTONS.CREATE',
  BUTTONS_BACK = 'BUTTONS.BACK',
  BUTTONS_NEXT = 'BUTTONS.NEXT',
  BUTTONS_CANCEL = 'BUTTONS.CANCEL',
  BUTTONS_DELETE = 'BUTTONS.DELETE',
  BUTTONS_SAVE_AND_PUBLISH = 'BUTTONS.SAVE_AND_PUBLISH',
  BUTTONS_INSTALL = 'BUTTONS.INSTALL',
  BUTTONS_OK = 'BUTTONS.OK',
  BUTTONS_UPDATE = 'BUTTONS.UPDATE',
  BUTTONS_PUBLISH = 'BUTTONS.PUBLISH',
  BUTTONS_OFFER_MATERIAL = 'BUTTONS.OFFER_MATERIAL',
  BUTTONS_RESERVE = 'BUTTONS.RESERVE',
  BUTTONS_GO_HOME = 'BUTTONS.GO_HOME',
  BUTTONS_GO_TRANSACTIONS = 'BUTTONS.GO_TRANSACTIONS',
  BUTTONS_SUBMIT_RESERVATION = 'BUTTONS.SUBMIT_RESERVATION',
  BUTTONS_SUBMIT_OFFER = 'BUTTONS.SUBMIT_OFFER',
  BUTTONS_REGISTER_TRIP = 'BUTTONS.REGISTER_TRIP',
  BUTTONS_IN_PROGRESS = 'BUTTONS.IN_PROGRESS',
  BUTTONS_COMPLETED = 'BUTTONS.COMPLETED',
  BUTTONS_SEARCH = 'BUTTONS.SEARCH',
  BUTTONS_CHANGE_STATUS = 'BUTTONS.CHANGE_STATUS',
  BUTTONS_EDIT_REGISTER = 'BUTTONS.EDIT_REGISTER',
  BUTTONS_CREATE_NEW_REQUEST = 'BUTTONS.CREATE_NEW_REQUEST',
  BUTTONS_CREATE_NEW_SUPPLY = 'BUTTONS.CREATE_NEW_SUPPLY',
  BUTTONS_CREATE_NEW_PROJECT = 'BUTTONS.CREATE_NEW_PROJECT',
  BUTTONS_VIEW_RECEPTION_CONTROL = 'BUTTONS.VIEW_RECEPTION_CONTROL',
  BUTTONS_RESET_PASSWORD = 'BUTTONS.RESET_PASSWORD', // Newly added
  BUTTONS_GO_TO_LISTING = 'BUTTONS.GO_TO_LISTING', // Newly added
  BUTTONS_INVITE_USER = 'BUTTONS.INVITE_USER',
  BUTTONS_SHARE_WITH_LINK = 'BUTTONS.SHARE_WITH_LINK',
  BUTTONS_NEW_DECLARATION = 'BUTTONS.NEW_DECLARATION',
  BUTTONS_SEND_TO_CUSTOMER = 'BUTTONS.SEND_TO_CUSTOMER',
  BUTTONS_REJECT_CONTENT = 'BUTTONS.REJECT_CONTENT',
  BUTTONS_APPROVE_CONTENT = 'BUTTONS.APPROVE_CONTENT',
  BUTTONS_REQUEST_PRICE = 'BUTTONS.REQUEST_PRICE',
  BUTTONS_ACCEPT_PRICE = 'BUTTONS.ACCEPT_PRICE',
  BUTTONS_SIGN = 'BUTTONS.SIGN',
  BUTTONS_CREATE_NEW_CUSTOMER = 'BUTTONS.CREATE_NEW_CUSTOMER',
  BUTTONS_CREATE_NEW_CONTACT_PERSON = 'BUTTONS.CREATE_NEW_CONTACT_PERSON',
  BUTTONS_UPLOAD_ANALYSIS_RESULT = 'BUTTONS.UPLOAD_ANALYSIS_RESULT',
  BUTTONS_ALL = 'BUTTONS.ALL',
  BUTTONS_DANGEROUS_WASTE = 'BUTTONS.DANGEROUS_WASTE',
  BUTTONS_RADIOACTIVE_WASTE = 'BUTTONS.RADIOACTIVE_WASTE',
  BUTTONS_READ_MORE = 'BUTTONS.READ_MORE', // Newly added
  BUTTONS_READ_LESS = 'BUTTONS.READ_LESS', // Newly added
  BUTTONS_SEE_COMPANY_LISTINGS = 'BUTTONS.SEE_COMPANY_LISTINGS', // Newly added
  BUTTONS_DELIVER = 'BUTTONS.DELIVER', // Newly added
  BUTTONS_MAKE_DECLARATION = 'BUTTONS.MAKE_DECLARATION',
  BUTTONS_CONFIRM = 'BUTTONS.CONFIRM',
  BUTTONS_CONTINUE_AS_COMPANY = 'BUTTONS.CONTINUE_AS_COMPANY',
  BUTTONS_REQUEST_AN_ACCOUNT = 'BUTTONS.REQUEST_AN_ACCOUNT',
  BUTTONS_CONTINUE_AS_COMPANY_USER = 'BUTTONS.CONTINUE_AS_COMPANY_USER',
  BUTTONS_CONTINUE_AS_GUEST = 'BUTTONS.CONTINUE_AS_GUEST',
  BUTTONS_SAVE_TEMPLATE = 'BUTTONS.SAVE_TEMPLATE',
  BUTTONS_SHARE = 'BUTTONS.SHARE',
  BUTTONS_SAVE_TEMPLATE_AS_NEW_VERSION = 'BUTTONS.SAVE_TEMPLATE_AS_NEW_VERSION',
  BUTTONS_SEND = 'BUTTONS.SEND',
  BUTTONS_SEND_VIA_EMAIL = 'BUTTONS.SEND_VIA_EMAIL',
  BUTTONS_TRANSFER = 'BUTTONS.TRANSFER',
  // DEFAULT
  DEFAULT_INTRODUCTION_TITLE = 'DEFAULT.INTRODUCTION_TITLE',
  DEFAULT_INTRODUCTION_CONTENT = 'DEFAULT.INTRODUCTION_CONTENT',
  // TITLE
  TITLE_EMAIL = 'TITLE.EMAIL',
  TITLE_PASSWORD = 'TITLE.PASSWORD',
  TITLE_FORGOT_PASSWORD = 'TITLE.FORGOT_PASSWORD',
  TITLE_NEW_PASSWORD = 'TITLE.NEW_PASSWORD',
  TITLE_CONFIRM_PASSWORD = 'TITLE.CONFIRM_PASSWORD', // Newly added
  TITLE_KEEP_ME_LOGIN = 'TITLE.KEEP_ME_LOGIN',
  TITLE_SIGN_UP = 'TITLE.SIGN_UP',
  TITLE_PHONE_NUMBER = 'TITLE.PHONE_NUMBER',
  TITLE_FULL_NAME = 'TITLE.FULL_NAME',
  TITLE_NAME = 'TITLE.NAME',
  TITLE_ADDRESS = 'TITLE.ADDRESS',
  TITLE_DEFAULT_FOCUS_AREA = 'TITLE.DEFAULT_FOCUS_AREA',
  TITLE_SELECT_COMPANY = 'TITLE.SELECT_COMPANY',
  TITLE_REQUIRE = 'TITLE.REQUIRE',
  TITLE_REQUIRE_PASSWORD = 'TITLE.REQUIRE_PASSWORD',
  TITLE_REQUIRE_EMAIL = 'TITLE.REQUIRE_EMAIL',
  TITLE_REQUIRE_PHONE_NUMBER = 'TITLE.REQUIRE_PHONE_NUMBER',
  TITLE_REQUIRE_ADDRESS = 'TITLE.REQUIRE_ADDRESS',
  TITLE_CONFIRMATION = 'TITLE.CONFIRMATION',
  TITLE_CONFIRMATION_CONTENT = 'TITLE.CONFIRMATION_CONTENT',
  TITLE_RESERVATION = 'TITLE.RESERVATION',
  TITLE_DESTINATION = 'TITLE.DESTINATION',
  TITLE_TRANSPORT = 'TITLE.TRANSPORT',
  TITLE_REQUEST_TRANSPORT = 'TITLE.REQUEST_TRANSPORT',
  TITLE_SUPPLIER_NOT_OFFER_TRANSPORT = 'TITLE.SUPPLIER_NOT_OFFER_TRANSPORT',
  TITLE_REQUESTER_ARRANGE_TRANSPORT = 'TITLE.REQUESTER_ARRANGE_TRANSPORT',
  TITLE_ARRANGE_TRANSPORT = 'TITLE.ARRANGE_TRANSPORT',
  TITLE_COMMENT = 'TITLE.COMMENT',
  TITLE_ADDITIONAL_INFO_FOR_REQUESTER = 'TITLE.ADDITIONAL_INFO_FOR_REQUESTER',
  TITLE_ADDITIONAL_INFO_FOR_SUPPLIER = 'TITLE.ADDITIONAL_INFO_FOR_SUPPLIER',
  TITLE_OFFER = 'TITLE.OFFER',
  TITLE_EDIT_SUPPLY = 'TITLE.EDIT_SUPPLY',
  TITLE_VOLUME = 'TITLE.VOLUME',
  TITLE_UPLOAD_DESCRIPTION = 'TITLE.UPLOAD_DESCRIPTION',
  TITLE_UPLOAD_LIMIT_DESCRIPTION = 'TITLE.UPLOAD_LIMIT_DESCRIPTION',
  TITLE_UPLOAD_ANALYSIS_RESULT = 'TITLE.UPLOAD_ANALYSIS_RESULT',
  TITLE_BEGIN_TIME = 'TITLE.BEGIN_TIME',
  TITLE_END_TIME = 'TITLE.END_TIME',
  TITLE_REQUIRE_CATEGORY_HELPTEXT = 'TITLE.REQUIRE_CATEGORY_HELPTEXT',
  TITLE_REQUIRE_QUALITY_HELPTEXT = 'TITLE.REQUIRE_QUALITY_HELPTEXT',
  TITLE_REQUIRE_VOLUME_HELPTEXT = 'TITLE.REQUIRE_VOLUME_HELPTEXT',
  TITLE_REQUIRE_HUB_HELPTEXT = 'TITLE.REQUIRE_HUB_HELPTEXT',
  TITLE_REQUIRE_ORIGIN_HELPTEXT = 'TITLE.REQUIRE_ORIGIN_HELPTEXT',
  TITLE_DOCUMENT = 'TITLE.DOCUMENT',
  TITLE_DESCRIPTION = 'TITLE.DESCRIPTION',
  TITLE_REGISTERED_REQUEST = 'TITLE.REGISTERED_REQUEST',
  TITLE_REGISTERED_SUPPLY = 'TITLE.REGISTERED_SUPPLY',
  TITLE_REGISTERED_PROJECT = 'TITLE.REGISTERED_PROJECT',
  TITLE_BY_YOUR_COMPANY = 'TITLE.BY_YOUR_COMPANY',
  TITLE_NO_ACTIVITY = 'TITLE.NO_ACTIVITY',
  TITLE_REQUIRE_AVAILABLE_PERIOD = 'TITLE.REQUIRE_AVAILABLE_PERIOD',
  TITLE_DESTINATION_HELPTEXT = 'TITLE.DESTINATION_HELPTEXT',
  TITLE_TRANSPORT_DATE_HELPTEXT = 'TITLE.TRANSPORT_DATE_HELPTEXT',
  TITLE_USER_IS_A_COMPANY = 'TITLE.USER_IS_A_COMPANY',
  TITLE_ORGANIZATION_NUMBER_HELPER = 'TITLE.TITLE_ORGANIZATION_NUMBER_HELPER',
  TITLE_MY_ACTIVITIES = 'TITLE.MY_ACTIVITIES',
  TITLE_USER_RECOMMENDATION = 'TITLE.USER_RECOMMENDATION',
  // LINK
  LINK_TERMS = 'LINK.TERMS',
  LINK_PRIVACY = 'LINK.PRIVACY',
  LINK_USE_OF_COOKIES = 'LINK.USE_OF_COOKIES',
  // PROFILE
  PROFILE_EDIT_PROFILE = 'PROFILE.EDIT_PROFILE',
  PROFILE_EMAIL_DISABLED = 'PROFILE.EMAIL_DISABLED',
  PROFILE_COMPANY_NAME = 'PROFILE.COMPANY_NAME',
  PROFILE_ADDRESS = 'PROFILE.ADDRESS',
  PROFILE_PRIVATE_PERSON = 'PROFILE.PRIVATE_PERSON',
  PROFILE_COMPANY = 'PROFILE.COMPANY',
  PROFILE_POSTAL_CODE = 'PROFILE.POSTAL_CODE',
  PROFILE_CITY = 'PROFILE.CITY',
  PROFILE_WEBSITE = 'PROFILE.WEBSITE',
  PROFILE_FULL_NAME = 'PROFILE.FULL_NAME',
  PROFILE_FULL_NAME_HELPER = 'PROFILE.FULL_NAME_HELPER',
  PROFILE_ROLE = 'PROFILE.ROLE',
  PROFILE_PHONE = 'PROFILE.PHONE',
  PROFILE_ACCEPT_TERMS = 'PROFILE.ACCEPT_TERMS',
  PROFILE_LAST_UPDATE = 'PROFILE.LAST_UPDATE',
  PROFILE_YOUR_PROFILE = 'PROFILE.YOUR_PROFILE',
  PROFILE_COMPANY_PROFILE = 'PROFILE.COMPANY_PROFILE',
  PROFILE_APPROVE_USER_TITLE = 'PROFILE.APPROVE_USER_TITLE',
  // MAP
  MAP_GEOLOCATION_NOT_FOUND = 'MAP.GEOLOCATION_NOT_FOUND',
  MAP_GEOLOCATION_PERMISSION = 'MAP.GEOLOCATION_PERMISSION',
  MAP_FIND_MY_LOCATION = 'MAP.FIND_MY_LOCATION',
  MAP_SELECT_LOCATION = 'MAP.SELECT_LOCATION',
  MAP_SELECT_LOCATION_NOT_FOUND = 'MAP.SELECT_LOCATION_NOT_FOUND',
  MAP_BUTTON_ADD_LOCATION = 'MAP.BUTTON_ADD_LOCATION',
  MAP_CURRENT_LOCATION = 'MAP.CURRENT_LOCATION',
  MAP_DISTANCE_CALCULATE_METHOD = 'MAP.DISTANCE_CALCULATE_METHOD',
  // REGISTER
  REGISTER_SELECT_PROJECT = 'REGISTER.SELECT_PROJECT',
  REGISTER_SELECT_PROJECT_HELPTEXT = 'REGISTER.SELECT_PROJECT_HELPTEXT',
  REGISTER_SELECT_CATEGORY = 'REGISTER.SELECT_CATEGORY',
  REGISTER_SUPPLY_INTRO = 'REGISTER.SUPPLY_INTRO',
  REGISTER_REQUEST_INTRO = 'REGISTER.REQUEST_INTRO',
  REGISTER_SUPPLY = 'REGISTER.SUPPLY',
  REGISTER_REQUEST = 'REGISTER.REQUEST',
  REGISTER_VOLUME = 'REGISTER.VOLUME',
  REGISTER_MATERIAL_NAME = 'REGISTER.MATERIAL_NAME',
  REGISTER_MATERIAL_DESTINATION = 'REGISTER.MATERIAL_DESTINATION',
  REGISTER_MATERIAL_NAME_HELPTEXT = 'REGISTER.MATERIAL_NAME_HELPTEXT',
  REGISTER_QUALITY = 'REGISTER.QUALITY',
  REGISTER_PRICE = 'REGISTER.PRICE',
  REGISTER_PRICE_HELPTEXT = 'REGISTER.PRICE_HELPTEXT',
  REGISTER_OFFER_TRANSPORT = 'REGISTER.OFFER_TRANSPORT',
  REGISTER_MATERIAL_LOCATION = 'REGISTER.MATERIAL_LOCATION',
  REGISTER_AVAILABLE_PERIOD = 'REGISTER.AVAILABLE_PERIOD',
  REGISTER_HUB_HELPTEXT = 'REGISTER.HUB_HELPTEXT',
  REGISTER_AGREED_HELPTEXT = 'REGISTER.AGREED_HELPTEXT',
  REGISTER_TOTAL_ACTIVITY_REQUEST = 'REGISTER.TOTAL_ACTIVITY_REQUEST',
  REGISTER_TOTAL_ACTIVITY_SUPPLY = 'REGISTER.TOTAL_ACTIVITY_SUPPLY',
  REGISTER_TOTAL_ACTIVITY_PLANNED = 'REGISTER.TOTAL_ACTIVITY_PLANNED',
  REGISTER_TOTAL_ACTIVITY_COMPLETED = 'REGISTER.TOTAL_ACTIVITY_COMPLETED',
  REGISTER_TRANSPORT_DATE = 'REGISTER.TRANSPORT_DATE',
  REGISTER_SIZE_FROM = 'REGISTER.SIZE_FROM',
  REGISTER_SIZE_TO = 'REGISTER.SIZE_TO',
  REGISTER_SIZE_TO_GREATER_THAN_SIZE_FROM = 'REGISTER.SIZE_TO_GREATER_THAN_SIZE_FROM',
  REGISTER_SIZE = 'REGISTER.SIZE',
  REGISTER_LOS_ANGELES_VALUE = 'REGISTER.LOS_ANGELES_VALUE',
  REGISTER_MICRO_DEVAL = 'REGISTER.MICRO_DEVAL',
  REGISTER_ORIGIN = 'REGISTER.ORIGIN',
  REGISTER_CE_APPROVED = 'REGISTER.CE_APPROVED',
  REGISTER_EPD_AVAILABLE = 'REGISTER.EPD_AVAILABLE',
  REGISTER_PUBLIC_LISTING = 'REGISTER.PUBLIC_LISTING',
  REGISTER_PRIVATE_LISTING = 'REGISTER.PRIVATE_LISTING',
  // PROJECT
  PROJECT_TITLE = 'PROJECT.TITLE',
  PROJECT_REGISTERED = 'PROJECT.REGISTERED',
  PROJECT_ADD_NEW = 'PROJECT.ADD_NEW',
  PROJECT_NAME = 'PROJECT.NAME',
  PROJECT_OWNER = 'PROJECT.OWNER',
  PROJECT_DESCRIPTION = 'PROJECT.DESCRIPTION',
  PROJECT_ACTIVE_PERIOD_START = 'PROJECT.ACTIVE_PERIOD_START',
  PROJECT_ACTIVE_PERIOD_END = 'PROJECT.ACTIVE_PERIOD_END',
  PROJECT_LOCATION_NAME = 'PROJECT.LOCATION_NAME',
  PROJECT_MAIN_MATERIAL = 'PROJECT.MAIN_MATERIAL',
  PROJECT_SECONDARY_MATERIAL = 'PROJECT.SECONDARY_MATERIAL',
  PROJECT_DATE_RANGE_REQUIRED = 'PROJECT.DATE_RANGE_REQUIRED',
  PROJECT_NO_PROJECT = 'PROJECT.NO_PROJECT',
  PROJECT_CREATE_PROJECT = 'PROJECT.CREATE_PROJECT',
  PROJECT_SUPPLY_DEMAND = 'PROJECT.SUPPLY_DEMAND',
  PROJECT_MASS_STATUS = 'PROJECT.MASS_STATUS',
  PROJECT_MASS_IN = 'PROJECT.MASS_IN',
  PROJECT_MASS_OUT = 'PROJECT.MASS_OUT',
  PROJECT_MASS_INTERNAL_PROJECT_IN = 'PROJECT.MASS_INTERNAL_PROJECT_IN',
  PROJECT_MASS_EXTERNAL_PROJECT_IN = 'PROJECT.MASS_EXTERNAL_PROJECT_IN',
  PROJECT_MASS_STORAGE_IN = 'PROJECT.MASS_STORAGE_IN',
  PROJECT_MASS_DEPOT_IN = 'PROJECT.MASS_DEPOT_IN',
  PROJECT_MASS_REUSED_IN = 'PROJECT.MASS_REUSED_IN',
  PROJECT_MASS_INTERNAL_PROJECT_OUT = 'PROJECT.MASS_INTERNAL_PROJECT_OUT',
  PROJECT_MASS_EXTERNAL_PROJECT_OUT = 'PROJECT.MASS_EXTERNAL_PROJECT_OUT',
  PROJECT_MASS_STORAGE_OUT = 'PROJECT.MASS_STORAGE_OUT',
  PROJECT_MASS_DEPOT_OUT = 'PROJECT.MASS_DEPOT_OUT',
  PROJECT_MASS_REUSED_OUT = 'PROJECT.MASS_REUSED_OUT',
  PROJECT_MASS_NEW_MINERAL_RESOURCE_IN = 'PROJECT.MASS_NEW_MINERAL_RESOURCE_IN',
  PROJECT_STORAGE_NAME = 'PROJECT.STORAGE_NAME',
  PROJECT_CREATE_STORAGE = 'PROJECT.CREATE_STORAGE',
  PROJECT_STORAGE_OWNER = 'PROJECT.STORAGE_OWNER',
  PROJECT_STORAGE_TITLE = 'PROJECT.STORAGE_TITLE',
  PROJECT_SELECT_ALL = 'PROJECT.SELECT_ALL',
  PROJECT_MASS_BUDGET = 'PROJECT.MASS_BUDGET',
  PROJECT_TYPE_OF_MASS = 'PROJECT.TYPE_OF_MASS',
  PROJECT_TRANSFERRED_TO = 'PROJECT.TRANSFERRED_TO',
  PROJECT_TRANSFERRED_FROM = 'PROJECT.TRANSFERRED_FROM',
  PROJECT_DISTANCE = 'PROJECT.DISTANCE',
  PROJECT_WEIGHT = 'PROJECT.WEIGHT',
  PROJECT_FACTOR = 'PROJECT.FACTOR',
  PROJECT_EMISSION = 'PROJECT.EMISSION',
  PROJECT_REGISTERRED_TRIPS = 'PROJECT.REGISTERRED_TRIPS',
  PROJECT_TRANSFER_MASS = 'PROJECT.TRANSFER_MASS',
  PROJECT_MASS_REUSED_OR_TRANSFER_TO = 'PROJECT.MASS_REUSED_OR_TRANSFER_TO',
  PROJECT_OTHER_PROJECT_IN_ROCKEXCHANGE = 'PROJECT.OTHER_PROJECT_IN_ROCKEXCHANGE',
  PROJECT_EXTERNAL_PROJECT = 'PROJECT.EXTERNAL_PROJECT',
  PROJECT_STORAGE_FACILITY = 'PROJECT.STORAGE_FACILITY',
  PROJECT_RECYCLING_FACILITY = 'PROJECT.RECYCLING_FACILITY',
  PROJECT_OTHER_ADDRESS = 'PROJECT.OTHER_ADDRESS',
  PROJECT_INVALID_MASS_VOLUME_HELPTEXT = 'PROJECT.INVALID_MASS_VOLUME_HELPTEXT',
  PROJECT_SELECT_STORAGE = 'PROJECT.SELECT_STORAGE',
  PROJECT_NEW_MASS_BUDGET = 'PROJECT.NEW_MASS_BUDGET',
  PROJECT_TOTAL_MASS_TRANSPORT = 'PROJECT.TOTAL_MASS_TRANSPORT',
  PROJECT_TRANSFER_ALL = 'PROJECT.TRANSFER_ALL',
  // HUB,
  HUB_TITLE = 'HUB.TITLE',
  HUB_LAST_UPDATE = 'HUB.LAST_UPDATE',
  HUB_NAME_TITLE = 'HUB.NAME_TITLE',
  HUB_LOCATION_TITLE = 'HUB.LOCATION_TITLE',
  HUB_MOBILE_NUMBER_TITLE = 'HUB.MOBILE_NUMBER_TITLE',
  HUB_SAVE = 'HUB.SAVE',
  HUB_RESET = 'HUB.RESET',
  HUB_ADD_NEW = 'HUB.ADD_NEW',
  HUB_ACTIVE = 'HUB.ACTIVE',
  HUB_INACTIVE = 'HUB.INACTIVE',
  HUB_HUBS_REGISTERED = 'HUB.HUBS_REGISTERED',
  HUB_BACK = 'HUB.BACK',
  HUB_NAME_REQUIRED = 'HUB.NAME_REQUIRED',
  HUB_LOGO_REQUIRED = 'HUB.LOGO_REQUIRED',
  HUB_SELECT = 'HUB.SELECT',
  HUB_CATEGORY = 'HUB.CATEGORY',
  HUB_DEPOT = 'HUB.DEPOT',
  HUB_PORT = 'HUB.PORT',
  HUB_RECYCLE = 'HUB.RECYCLE',
  HUB_OCCURRENCE = 'HUB.OCCURRENCE',
  HUB_CUSTOM = 'HUB.CUSTOM',
  HUB_SUBCATEGORY = 'HUB.SUBCATEGORY',
  HUB_DESCRIPTION = 'HUB.DESCRIPTION',
  HUB_SUB_TITLE = 'HUB.SUB_TITLE',
  HUB_MAIN_ROCK = 'HUB.MAIN_ROCK',
  HUB_SIGNIFICANCE = 'HUB.SIGNIFICANCE',
  HUB_OCCURRENCE_ID = 'HUB.OCCURRENCE_ID', // Newly added
  HUB_WEBSITE = 'HUB.WEBSITE',
  HUB_CONTACT_PERSON = 'HUB.CONTACT_PERSON',
  HUB_CONTACT_PERSON_FILLER = 'HUB.CONTACT_PERSON_FILLER',
  HUB_UNSPECIFIED = 'HUB.UNSPECIFIED',
  HUB_SUB_HUB_UNSPECIFIED = 'HUB.UNSPECIFIED',
  HUB_SUB_HUB_QUARRY = 'HUB.QUARRY',
  HUB_SUB_HUB_MASS_ROOF = 'HUB.MASS_ROOF',
  HUB_SUB_HUB_GRAVEL_ROOF = 'HUB.GRAVEL_ROOF',
  HUB_PUKK = 'HUB.PUKK',
  HUB_SAND_GRUS = 'HUB.SAND_GRUS',
  HUB_LOCAL_SIGNIFICANCE = 'HUB.LOCAL_SIGNIFICANCE',
  HUB_LITTLE_SIGNIFICANCE = 'HUB.LITTLE_SIGNIFICANCE',
  HUB_NOT_RATED = 'HUB.NOT_RATED',
  HUB_NATIONAL_SIGNIFICANCE = 'HUB.NATIONAL_SIGNIFICANCE',
  HUB_INTERNATIONAL_SIGNIFICANCE = 'HUB.INTERNATIONAL_SIGNIFICANCE',
  HUB_USE_CUSTOM_COLOR = 'HUB.USE_CUSTOM_COLOR',
  HUB_DELETE_TITLE = 'HUB.DELETE_TITLE',
  HUB_DELETE_MESSAGE = 'HUB.DELETE_MESSAGE',
  HUB_SEARCHFIELD_LABEL = 'HUB.SEARCHFIELD_LABEL',
  HUB_SEARCHFIELD_PLACEHOLDER = 'HUB.SEARCHFIELD_PLACEHOLDER',
  HUB_ORGANIZATION_ID = 'HUB.ORGANIZATION_ID',
  HUB_ACCEPT_POLLUTED_MASS = 'HUB.ACCEPT_POLLUTED_MASS',
  HUB_CLASS = 'HUB.CLASS',
  HUB_DEPOT_CAT = 'HUB.DEPOT_CAT',
  HUB_DEPOT_SALES_INFO = 'HUB.DEPOT_SALES_INFO',
  HUB_DEPOT_DELIVER_TITLE = 'HUB.DEPOT_DELIVER_TITLE', // Newly added
  HUB_DEPOT_DELIVER_MESSAGE = 'HUB.DEPOT_DELIVER_MESSAGE', // Newly added
  HUB_IS_WASH = 'HUB.IS_WASH',
  HUB_IS_PRIVATE = 'HUB.IS_PRIVATE',
  HUB_DOES_SALES = 'HUB.DOES_SALES',
  HUB_LOGO = 'HUB.LOGO',
  HUB_PROFILE_IMAGE = 'HUB.PROFILE_IMAGE',
  // MATERIAL
  MATERIAL_TITLE = 'MATERIAL.TITLE',
  MATERIAL_LAST_UPDATE = 'MATERIAL.LAST_UPDATE',
  MATERIAL_NAME_TITLE = 'MATERIAL.NAME_TITLE',
  MATERIAL_ALTERNATIVE_NAME = 'MATERIAL.ALTERNATIVE_NAME',
  MATERIAL_SIZE_FROM = 'MATERIAL.SIZE_FROM',
  MATERIAL_SIZE_TO = 'MATERIAL.SIZE_TO',
  MATERIAL_LOCATION_TITLE = 'MATERIAL.LOCATION_TITLE',
  MATERIAL_SAVE = 'MATERIAL.SAVE',
  MATERIAL_RESET = 'MATERIAL.RESET',
  MATERIAL_ADD_NEW = 'MATERIAL.ADD_NEW',
  MATERIAL_ACTIVE = 'MATERIAL.ACTIVE',
  MATERIAL_INACTIVE = 'MATERIAL.INACTIVE',
  MATERIAL_MATERIALS_REGISTERED = 'MATERIAL.MATERIALS_REGISTERED',
  MATERIAL_BACK = 'MATERIAL.BACK',
  MATERIAL_DESCRIPTION = 'MATERIAL.DESCRIPTION',
  MATERIAL_NAME_REQUIRED = 'MATERIAL.NAME_REQUIRED',
  MATERIAL_DESCRIPTION_REQUIRED = 'MATERIAL.DESCRIPTION_REQUIRED',
  MATERIAL_SIZE_TO_GREATER_THAN_SIZE_FROM = 'MATERIAL.SIZE_TO_GREATER_THAN_SIZE_FROM',
  MATERIAL_SIZE_TO_MUST_BE_GREATER_THAN_SIZE_FROM = 'MATERIAL.SIZE_TO_MUST_BE_GREATER_THAN_SIZE_FROM',
  MATERIAL_SIZE_FROM_MUST_BE_LOWER_THAN_SIZE_TO = 'MATERIAL.SIZE_FROM_MUST_BE_LOWER_THAN_SIZE_TO',
  MATERIAL_INVALID_SIZE = 'MATERIAL.INVALID_SIZE',
  MATERIAL_DELETE_TITLE = 'MATERIAL.DELETE_TITLE',
  MATERIAL_DELETE_MESSAGE = 'MATERIAL.DELETE_MESSAGE',
  MATERIAL_TITLE_FORM_ADDNEW = 'MATERIAL.TITLE_FORM_ADDNEW',
  MATERIAL_EAL_CODE = 'MATERIAL.EAL_CODE',
  MATERIAL_STANDARD = 'MATERIAL.STANDARD',
  MATERIAL_IS_WASTE = 'MATERIAL.IS_WASTE',
  MATERIAL_IS_HAZARDOUS = 'MATERIAL.IS_HAZARDOUS',
  // CO2EMISSION
  CO2EMISSION_TITLE = 'CO2EMISSION.TITLE',
  CO2EMISSION_LAST_UPDATE = 'CO2EMISSION.LAST_UPDATE',
  CO2EMISSION_VALUE_TITLE = 'CO2EMISSION.VALUE_TITLE',
  CO2EMISSION_UPDATE = 'CO2EMISSION.UPDATE',
  CO2EMISSION_RESET = 'CO2EMISSION.RESET',
  CO2EMISSION_PER_KM = 'CO2EMISSION.PER_KM',
  // SLIDER
  SLIDER_MAP_SELECT_MATERIAL_VOLUME = 'SLIDER.MAP_SELECT_MATERIAL_VOLUME',
  SLIDER_MAP_SELECT_MATERIAL_TONNE = 'SLIDER.MAP_SELECT_MATERIAL_TONNE',
  SLIDER_MAP_SELECT_MATERIAL_AVAILABLE_VOLUME = 'SLIDER.MAP_SELECT_MATERIAL_AVAILABLE_VOLUME',
  SLIDER_MAP_SELECT_MATERIAL_READ_MORE = 'SLIDER.MAP_SELECT_MATERIAL_READ_MORE',
  SLIDER_MAP_SELECT_MATERIAL_CLOSE = 'SLIDER.MAP_SELECT_MATERIAL_CLOSE',
  SLIDER_MAP_SELECT_MATERIAL_OFFERED_REQUESTED = 'SLIDER.MAP_SELECT_MATERIAL_OFFERED_REQUESTED',
  SLIDER_MAP_SELECT_MATERIAL_OFFERED = 'SLIDER.MAP_SELECT_MATERIAL_OFFERED',
  SLIDER_MAP_SELECT_MATERIAL_REQUESTED = 'SLIDER.MAP_SELECT_MATERIAL_REQUESTED',
  SLIDER_MAP_SELECT_MATERIAL_MATERIALS_REGISTERED = 'SLIDER.MAP_SELECT_MATERIAL_MATERIALS_REGISTERED',
  SLIDER_MAP_SELECT_HUBS_INFORMATION = 'SLIDER.MAP_SELECT_HUBS_INFORMATION',
  SLIDER_MAP_SELECT_EXPAND = 'SLIDER.MAP_SELECT_EXPAND',
  SLIDER_MAP_SELECT_COLLAPSE = 'SLIDER.MAP_SELECT_COLLAPSE',
  // PROMPTS
  PROMPTS_INSTALL_TITLE = 'PROMPTS.INSTALL_TITLE',
  PROMPTS_INSTALL_DESCRIPTION = 'PROMPTS.INSTALL_DESCRIPTION',
  PROMPTS_INSTALL_DESCRIPTION_IOS = 'PROMPTS.INSTALL_DESCRIPTION_IOS',
  // HOME
  HOME_SEARCH_FOR_SUPPLY = 'HOME.SEARCH_FOR_SUPPLY',
  HOME_SEARCH_FOR_REQUEST = 'HOME.SEARCH_FOR_REQUEST',
  HOME_SEARCH_RESULT_SUPPLY = 'HOME.SEARCH_RESULT_SUPPLY',
  HOME_SEARCH_RESULT_REQUEST = 'HOME.SEARCH_RESULT_REQUEST',
  HOME_SUPPLY_VIEW = 'HOME.SUPPLY_VIEW',
  HOME_REQUEST_VIEW = 'HOME.REQUEST_VIEW',
  HOME_MAP_VIEW = 'HOME.MAP_VIEW',
  HOME_GET_DIRECTION = 'HOME.GET_DIRECTION',
  HOME_SELECT_MATERIAL_TYPE = 'HOME.SELECT_MATERIAL_TYPE',
  // ACTIVITY
  ACTIVITY_REGISTERED_TITLE = 'ACTIVITY.REGISTERED_TITLE',
  ACTIVITY_PLANNED_TITLE = 'ACTIVITY.PLANNED_TITLE',
  ACTIVITY_COMPLETED_TITLE = 'ACTIVITY.COMPLETED_TITLE',
  ACTIVITY_TRANSPORT_STATUS = 'ACTIVITY.TRANSPORT_STATUS',
  ACTIVITY_ADD_TRANSPORTED_VOLUME = 'ACTIVITY.ADD_TRANSPORTED_VOLUME',
  ACTIVITY_UPDATE_TRANSACTION_TITLE = 'ACTIVITY.UPDATE_TRANSACTION_TITLE',
  ACTIVITY_REMAINING_VOLUME = 'ACTIVITY.REMAINING_VOLUME',
  // COMMONS
  COMMONS_OR = 'COMMONS.OR',
  COMMONS_TO = 'COMMONS.TO',
  COMMONS_TONNE = 'COMMONS.TONNE',
  COMMONS_REQUIRED = 'COMMONS.REQUIRED',
  COMMONS_YES = 'COMMONS.YES',
  COMMONS_NO = 'COMMONS.NO',
  COMMONS_EMPTY = 'COMMONS.EMPTY',
  COMMONS_CHANGED = 'COMMONS.CHANGED',
  COMMONS_CREATED = 'COMMONS.CREATED',
  COMMONS_DELETED = 'COMMONS.DELETED',
  COMMONS_NO_REASON = 'COMMONS.NO_REASON',
  COMMONS_NO_RESULTS_FOUND = 'COMMONS.NO_RESULTS_FOUND',
  COMMONS_BACK = 'COMMONS.BACK',
  COMMONS_TAKE_OR_UPLOAD_PICTURE = 'COMMONS.TAKE_OR_UPLOAD_PICTURE',
  COMMONS_COMPLETE = 'COMMONS.COMPLETE',
  COMMONS_DO_YOU_WANT_TO_CONTINUE = 'COMMONS.DO_YOU_WANT_TO_CONTINUE',
  COMMONS_SEND_EMAIL_TO = 'COMMONS.SEND_EMAIL_TO',
  COMMONS_VOLUME = 'COMMONS.VOLUME',
  COMMONS_CLEAR = 'COMMONS.CLEAR',
  COMMONS_SAVE = 'COMMONS.SAVE',
  COMMONS_DECLINE = 'COMMONS.DECLINE',
  COMMONS_SEARCH_FIELD_LABEL = 'COMMONS.SEARCH_FIELD_LABEL',
  COMMONS_OPEN_IN_MAP = 'COMMONS.OPEN_IN_MAP',
  COMMONS_START_DATE = 'COMMONS.START_DATE',
  COMMONS_END_DATE = 'COMMONS.END_DATE',
  COMMONS_LOCATION = 'COMMONS.LOCATION',
  // COMPANY
  COMPANY_CREATE = 'COMPANY.CREATE',
  COMPANY_NAME = 'COMPANY.NAME',
  COMPANY_DESCRIPTION = 'COMPANY.DESCRIPTION',
  COMPANY_SHORT_DESCRIPTION = 'COMPANY.SHORT_DESCRIPTION',
  COMPANY_RECOMMEND_LOGO = 'COMPANY.RECOMMEND_LOGO',
  COMPANY_LOGO_REQUIRED = 'COMPANY.LOGO_REQUIRED',
  COMPANY_IMAGE_REQUIRED = 'COMPANY.IMAGE_REQUIRED',
  COMPANY_ADDRESS = 'COMPANY.ADDRESS',
  COMPANY_POSTAL_CODE = 'COMPANY.POSTAL_CODE',
  COMPANY_CITY = 'COMPANY.CITY',
  COMPANY_WEBSITE = 'COMPANY.WEBSITE',
  COMPANY_CONTACT_SALES = 'COMPANY.CONTACT_SALES',
  COMPANY_CONTACT_PRIMARY = 'COMPANY.CONTACT_PRIMARY',
  COMPANY_PHONE = 'COMPANY.PHONE',
  COMPANY_LAST_UPDATE = 'COMPANY.LAST_UPDATE',
  COMPANY_NO_COMPANY = 'COMPANY.NO_COMPANY',
  COMPANY_ORGANIZATION_NUMBER = 'COMPANY.ORGANIZATION_NUMBER',
  COMPANY_ADD_NEW = 'COMPANY.ADD_NEW',
  COMPANY_TITLE = 'COMPANY.TITLE',
  COMPANY_COMPANIES_REGISTERED = 'COMPANY.COMPANIES_REGISTERED',
  COMPANY_VALID_ORGANIZATION_NUMBER = 'COMPANY.VALID_ORGANIZATION_NUMBER',
  COMPANY_DUPLICATED_ORGANIZATION_NUMBER = 'COMPANY.DUPLICATED_ORGANIZATION_NUMBER',
  // REPORT
  REPORT_NOT_AVAILABLE = 'REPORT.NOT_AVAILABLE',
  REPORT_TITLE = 'REPORT.TITLE',
  REPORT_CLEAR_PERIOD = 'REPORT.CLEAR_PERIOD',
  REPORT_EXPORT = 'REPORT.EXPORT',
  REPORT_CARBON_FOOTPRINT_REDUCED = 'REPORT.CARBON_FOOTPRINT_REDUCED',
  REPORT_COMPLETED_TRIP = 'REPORT.COMPLETED_TRIP',
  REPORT_KILOMETER_DRIVEN = 'REPORT.KILOMETER_DRIVEN',
  REPORT_CO2_EMISSION = 'REPORT.CO2_EMISSION',
  REPORT_DEPOSIT_TO_HUB = 'REPORT.DEPOSIT_TO_HUB',
  REPORT_WITH_MARKETPLACE = 'REPORT.WITH_MARKETPLACE',
  REPORT_WITHOUT_MARKETPLACE = 'REPORT.WITHOUT_MARKETPLACE',
  REPORT_REDUCTION = 'REPORT.REDUCTION',
  REPORT_BEGIN_TIME = 'REPORT.BEGIN_TIME',
  REPORT_END_TIME = 'REPORT.END_TIME',
  REPORT_REQUEST_PERIOD = 'REPORT.REQUEST_PERIOD',
  REPORT_CHOOSE_TYPE_EXPORT = 'REPORT.CHOOSE_TYPE_EXPORT',
  REPORT_SELECT_ALL_OPTION = 'REPORT.SELECT_ALL_OPTION',
  REPORT_REGION_OPTION = 'REPORT.SELECT_REGION_OPTION',
  REPORT_CO2_EMISSION_OPTION = 'REPORT.CO2_EMISSION_OPTION',
  REPORT_EMISSION_REDUCTION_OPTION = 'REPORT.EMISSION_REDUCTION_OPTION',
  REPORT_KILOMETERS_DRIVEN_OPTION = 'REPORT.KILOMETERS_DRIVEN_OPTION',
  REPORT_KILOMETERS_TO_HUB_OPTION = 'REPORT.KILOMETERS_TO_HUB_OPTION',
  REPORT_KILOMETERS_REDUCED_OPTION = 'REPORT.KILOMETERS_REDUCED_OPTION',
  REPORT_REDUCTION_IN_PERCENT_OPTION = 'REPORT.REDUCTION_IN_PERCENT_OPTION',
  // DATA EXTRACTION
  DATA_EXTRACTION_NOT_AVAILABLE = 'DATA_EXTRACTION.NOT_AVAILABLE',
  DATA_EXTRACTION_TITLE = 'DATA_EXTRACTION.TITLE',
  DATA_EXTRACTION_CLEAR_PERIOD = 'DATA_EXTRACTION.CLEAR_PERIOD',
  DATA_EXTRACTION_EXPORT = 'DATA_EXTRACTION.EXPORT',
  DATA_EXTRACTION_CARBON_FOOTPRINT_REDUCED = 'DATA_EXTRACTION.CARBON_FOOTPRINT_REDUCED',
  DATA_EXTRACTION_COMPLETED_TRIP = 'DATA_EXTRACTION.COMPLETED_TRIP',
  DATA_EXTRACTION_KILOMETER_DRIVEN = 'DATA_EXTRACTION.KILOMETER_DRIVEN',
  DATA_EXTRACTION_CO2_EMISSION = 'DATA_EXTRACTION.CO2_EMISSION',
  DATA_EXTRACTION_DEPOSIT_TO_HUB = 'DATA_EXTRACTION.DEPOSIT_TO_HUB',
  DATA_EXTRACTION_WITH_MARKETPLACE = 'DATA_EXTRACTION.WITH_MARKETPLACE',
  DATA_EXTRACTION_WITHOUT_MARKETPLACE = 'DATA_EXTRACTION.WITHOUT_MARKETPLACE',
  DATA_EXTRACTION_REDUCTION = 'DATA_EXTRACTION.REDUCTION',
  DATA_EXTRACTION_BEGIN_TIME = 'DATA_EXTRACTION.BEGIN_TIME',
  DATA_EXTRACTION_END_TIME = 'DATA_EXTRACTION.END_TIME',
  DATA_EXTRACTION_REQUEST_PERIOD = 'DATA_EXTRACTION.REQUEST_PERIOD',
  DATA_EXTRACTION_CHOOSE_TYPE_EXPORT = 'DATA_EXTRACTION.CHOOSE_TYPE_EXPORT',
  // TRANSPORT
  TRANSPORT_TITLE = 'TRANSPORT.TITLE',
  TRANSPORT_LAST_UPDATE = 'TRANSPORT.LAST_UPDATE',
  TRANSPORT_NAME_TITLE = 'TRANSPORT.NAME_TITLE',
  TRANSPORT_ADD_NEW = 'TRANSPORT.ADD_NEW',
  TRANSPORT_ACTIVE = 'TRANSPORT.ACTIVE',
  TRANSPORT_INACTIVE = 'TRANSPORT.INACTIVE',
  TRANSPORT_REGISTERED = 'TRANSPORT.REGISTERED',
  TRANSPORT_NAME_REQUIRED = 'TRANSPORT_NAME.REQUIRED',
  TRANSPORT_EURO_STANDARD_TITLE_REQUIRED = 'TRANSPORT.EURO_STANDARD_TITLE_REQUIRED',
  TRANSPORT_CAPACITY_REQUIRED = 'TRANSPORT.CAPACITY_REQUIRED',
  TRANSPORT_EMISSIONS_REQUIRED = 'TRANSPORT.EMISSIONS.REQUIRED',
  TRANSPORT_EURO_STANDARD_TITLE = 'TRANSPORT.EURO_STANDARD_TITLE',
  TRANSPORT_SELECT = 'TRANSPORT.SELECT',
  TRANSPORT_CAPACITY_TITLE = 'TRANSPORT.CAPACITY_TITLE',
  TRANSPORT_EMISSIONS_TITLE = 'TRANSPORT.EMISSIONS_TITLE',
  TRANSPORT_FUEL = 'TRANSPORT.FUEL',
  TRANSPORT_TYPE = 'TRANSPORT.TYPE',
  TRANSPORT_RIGID_TRUCK = 'TRANSPORT.RIGID_TRUCK',
  TRANSPORT_ARTICULATED_TRUCK = 'TRANSPORT.ARTICULATED_TRUCK',
  TRANSPORT_DIESEL = 'TRANSPORT.DIESEL',
  TRANSPORT_PETROL = 'TRANSPORT.PETROL',
  TRANSPORT_ELECTRIC = 'TRANSPORT.ELECTRIC',
  TRANSPORT_HYDROGEN = 'TRANSPORT.HYDROGEN',
  TRANSPORT_HBEFA_CLASS = 'TRANSPORT.HBEFA_CLASS',
  TRANSPORT_PLATE_NUMBER = 'TRANSPORT.PLATE_NUMBER',
  TRANSPORT_BRAND = 'TRANSPORT.BRAND',
  TRANSPORT_MODEL = 'TRANSPORT.MODEL',
  TRANSPORT_WEIGHT = 'TRANSPORT.WEIGHT',
  // COPYRIGHT
  COPYRIGHT_TITLE = 'COPYRIGHT.TITLE',
  // NOTIFY
  NOTIFY_SIGN_UP_SUCCESS = 'NOTIFY.SIGN_UP_SUCCESS',
  NOTIFY_SEND_PASSWORD_RECOVERY_EMAIL = 'NOTIFY.SEND_PASSWORD_RECOVERY_EMAIL', // Newly added
  NOTIFY_UPDATE_PASSWORD_SUCCESS = 'NOTIFY.UPDATE_PASSWORD_SUCCESS', // Newly added
  NOTIFY_VERIFY_EMAIL_SUCCESS = 'NOTIFY.VERIFY_EMAIL_SUCCESS',
  NOTIFY_ACCEPT_TERMS_WARNING = 'NOTIFY.ACCEPT_TERMS_WARNING',
  NOTIFY_NEW_VERSION = 'NOTIFY.NEW_VERSION',
  NOTIFY_EMPTY_FILE = 'NOTIFY.EMPTY_FILE',
  NOTIFY_NO_DATA_EXPORT = 'NOTIFY.NO_DATA_EXPORT',
  NOTIFY_CREATE_SUPPLY_SUCCESS = 'NOTIFY.CREATE_SUPPLY_SUCCESS',
  NOTIFY_CREATE_SUPPLY_ERROR = 'NOTIFY.CREATE_SUPPLY_ERROR',
  NOTIFY_UPDATE_SUPPLY_SUCCESS = 'NOTIFY.UPDATE_SUPPLY_SUCCESS',
  NOTIFY_UPDATE_SUPPLY_ERROR = 'NOTIFY.UPDATE_SUPPLY_ERROR',
  NOTIFY_CREATE_REQUEST_SUCCESS = 'NOTIFY.CREATE_REQUEST_SUCCESS',
  NOTIFY_CREATE_REQUEST_ERROR = 'NOTIFY.CREATE_REQUEST_ERROR',
  NOTIFY_UPDATE_REQUEST_SUCCESS = 'NOTIFY.UPDATE_REQUEST_SUCCESS',
  NOTIFY_UPDATE_REQUEST_ERROR = 'NOTIFY.UPDATE_REQUEST_ERROR',
  NOTIFY_CREATE_TRIP_SUCCESS = 'NOTIFY.CREATE_TRIP_SUCCESS',
  NOTIFY_UPDATE_TRANSACTION_SUCCESS = 'NOTIFY.UPDATE_TRANSACTION_SUCCESS',
  NOTIFY_DELETE_TRANSACTION_SUCCESS = 'NOTIFY.DELETE_TRANSACTION_SUCCESS',
  NOTIFY_UPDATE_TRIP_SUCCESS = 'NOTIFY.UPDATE_TRIP_SUCCESS',
  NOTIFY_UPDATE_TRIP_ERROR = 'NOTIFY.UPDATE_TRIP_ERROR',
  NOTIFY_INVITE_USER_SUCCESS = 'NOTIFY.INVITE_USER_SUCCESS',
  NOTIFY_INVITE_USER_ERROR = 'NOTIFY.INVITE_USER_ERROR',
  NOTIFY_CONFIRM_NAVIGATE_AWAY = 'NOTIFY.CONFIRM_NAVIGATE_AWAY',
  NOTIFY_UPLOAD_FILE_ERROR = 'NOTIFY.UPLOAD_FILE_ERROR',
  NOTIFY_CREATE_CONTACT_PERSON = 'NOTIFY.CREATE_CONTACT_PERSON',
  NOTIFY_CREATE_PROJECT_SUCCESS = 'NOTIFY.CREATE_PROJECT_SUCCESS',
  NOTIFY_UPDATE_PROJECT_SUCCESS = 'NOTIFY.UPDATE_PROJECT_SUCCESS',
  NOTIFY_CREATE_PROJECT_ERROR = 'NOTIFY.CREATE_PROJECT_ERROR',
  NOTIFY_CREATE_CUSTOMER_SUCCESS = 'NOTIFY.CREATE_CUSTOMER_SUCCESS',
  NOTIFY_UPDATE_CUSTOMER_SUCCESS = 'NOTIFY.UPDATE_CUSTOMER_SUCCESS',
  NOTIFY_APPROVE_USER_ERROR = 'NOTIFY.APPROVE_USER_ERROR',
  NOTIFY_APPROVE_USER_SUCESS = 'NOTIFY.APPROVE_USER_SUCESS',
  NOTIFY_SAVE_TEMPLATE_SUCCESS = 'NOTIFY.SAVE_TEMPLATE_SUCCESS',
  NOTIFY_UPDATE_TEMPLATE_SUCCESS = 'NOTIFY.UPDATE_TEMPLATE_SUCCESS',
  NOTIFY_SAVE_TEMPLATE_AS_NEW_VERSION_SUCCESS = 'NOTIFY.SAVE_TEMPLATE_AS_NEW_VERSION_SUCCESS',
  NOTIFY_SAVE_TEMPLATE_ERROR = 'NOTIFY.SAVE_TEMPLATE_ERROR',
  NOTIFY_EMAIL_SENT = 'NOTIFY.EMAIL_SENT',
  NOTIFY_EMAIL_NOT_REGISTERED = 'NOTIFY.EMAIL_NOT_REGISTERED',
  // TRIP
  TRIP_VOLUME_REMAIN = 'TRIP.VOLUME_REMAIN',
  TRIP_REGISTER = 'TRIP.REGISTER',
  TRIP_NO_REGISTER = 'TRIP.NO_REGISTER',
  TRIP_ORIGIN = 'TRIP.ORIGIN', // Newly added
  TRIP_DESTINATION = 'TRIP.DESTINATION', // Newly added
  TRIP_TRANSPORT_DATE = 'TRIP.TRANSPORT_DATE',
  TRIP_TRANSPORTED_VOLUME = 'TRIP.TRANSPORTED_VOLUME',
  TRIP_DELIVERED_TO = 'TRIP.DELIVERED_TO',
  TRIP_COUNTERPART = 'TRIP.COUNTERPART',
  TRIP_TITLE = 'TRIP.TITLE',
  TRIP_PLATE_NUMBER = 'TRIP.PLATE_NUMBER',
  // LAYER
  LAYER_TITLE_01 = 'LAYER.TITLE_01',
  LAYER_TITLE_02 = 'LAYER.TITLE_02',
  LAYER_TITLE_POLLUTION = 'LAYER.TITLE_POLLUTION',
  LAYER_TITLE_MINERAL_EXTRACTION = 'LAYER.TITLE_MINERAL_EXTRACTION',
  LAYER_TITLE_GRUS_PUKK_WMS5 = 'LAYER.GRAVEL_AND_HARD_ROCK_AGGREGATE_WMS.GRUS_PUKK_WMS5',
  LAYER_TITLE_GRUS_PUKK_OMRADE_BETYDNING = 'LAYER.GRAVEL_AND_HARD_ROCK_AGGREGATE_WMS.GRUS_PUKK_OMRADE_BETYDNING',
  LAYER_TITLE_GRUS_PUKK_REGISTRERING_BETYDNING = 'LAYER.GRAVEL_AND_HARD_ROCK_AGGREGATE_WMS.GRUS_PUKK_REGISTRERING_BETYDNING',
  LAYER_TITLE_GRUS_PUKK_OMRADE = 'LAYER.GRAVEL_AND_HARD_ROCK_AGGREGATE_WMS.GRUS_PUKK_OMRADE',
  LAYER_TITLE_GRUS_PUKK_REGISTRERING = 'LAYER.GRAVEL_AND_HARD_ROCK_AGGREGATE_WMS.GRUS_PUKK_REGISTRERING',
  LAYER_TITLE_GRUS_PUKK_STATISTIKKPUNKT_NASJONAL = 'LAYER.GRAVEL_AND_HARD_ROCK_AGGREGATE_WMS.GRUS_PUKK_STATISTIKKPUNKT_NASJONAL',
  LAYER_TITLE_GRUS_PUKK_STATISTIKKPUNKT_REGIONAL = 'LAYER.GRAVEL_AND_HARD_ROCK_AGGREGATE_WMS.GRUS_PUKK_STATISTIKKPUNKT_REGIONAL',
  LAYER_TITLE_GRUS_PUKK_PROVEPUNKT = 'LAYER.GRAVEL_AND_HARD_ROCK_AGGREGATE_WMS.GRUS_PUKK_PROVEPUNKT',
  LAYER_TITLE_GRUS_PUKK_OBSERVASJON = 'LAYER.GRAVEL_AND_HARD_ROCK_AGGREGATE_WMS.GRUS_PUKK_OBSERVASJON',
  LAYER_TITLE_GRUS_PUKK_UTTAK = 'LAYER.GRAVEL_AND_HARD_ROCK_AGGREGATE_WMS.GRUS_PUKK_UTTAK',
  LAYER_TITLE_STEINTIPP = 'LAYER.GRAVEL_AND_HARD_ROCK_AGGREGATE_WMS.STEINTIPP',
  LAYER_TITLE_LOSMASSER_WMS = 'LAYER.SUPERFICIAL_DEPOSITS_WMS.LOSMASSER_WMS',
  LAYER_TITLE_LOSMASSE_NORGE = 'LAYER.SUPERFICIAL_DEPOSITS_WMS.LOSMASSE_NORGE',
  LAYER_TITLE_LOSMASSE_FLATE_N1000_WEB = 'LAYER.SUPERFICIAL_DEPOSITS_WMS.LOSMASSE_FLATE_N1000_WEB',
  LAYER_TITLE_LOSMASSE_FLATE_N1000 = 'LAYER.SUPERFICIAL_DEPOSITS_WMS.LOSMASSE_FLATE_N1000',
  LAYER_TITLE_LOSMASSE_FLATE_LABEL = 'LAYER.SUPERFICIAL_DEPOSITS_WMS.LOSMASSE_FLATE_LABEL',
  LAYER_TITLE_LOSMASSE_FLATE = 'LAYER.SUPERFICIAL_DEPOSITS_WMS.LOSMASSE_FLATE',
  LAYER_TITLE_LOSMASSE_GRENSE = 'LAYER.SUPERFICIAL_DEPOSITS_WMS.LOSMASSE_GRENSE',
  LAYER_TITLE_LOSMASSE_FORMLINJE_N1000 = 'LAYER.SUPERFICIAL_DEPOSITS_WMS.LOSMASSE_FORMLINJE_N1000',
  LAYER_TITLE_SALTVANNSAVSETNING_NORGE = 'LAYER.SUPERFICIAL_DEPOSITS_WMS.SALTVANNSAVSETNING_NORGE',
  LAYER_TITLE_SALTVANNSAVSETNING_N1000_WEB = 'LAYER.SUPERFICIAL_DEPOSITS_WMS.SALTVANNSAVSETNING_N1000_WEB',
  LAYER_TITLE_SALTVANNSAVSETNING_N1000 = 'LAYER.SUPERFICIAL_DEPOSITS_WMS.SALTVANNSAVSETNING_N1000',
  LAYER_TITLE_SALTVANNSAVSETNING = 'LAYER.SUPERFICIAL_DEPOSITS_WMS.SALTVANNSAVSETNING',
  LAYER_TITLE_MEKTIGHET_NORGE = 'LAYER.SUPERFICIAL_DEPOSITS_WMS.MEKTIGHET_NORGE',
  LAYER_TITLE_MEKTIGHET_N1000_WEB = 'LAYER.SUPERFICIAL_DEPOSITS_WMS.MEKTIGHET_N1000_WEB',
  LAYER_TITLE_MEKTIGHET_N1000 = 'LAYER.SUPERFICIAL_DEPOSITS_WMS.MEKTIGHET_N1000',
  LAYER_TITLE_MEKTIGHET = 'LAYER.SUPERFICIAL_DEPOSITS_WMS.MEKTIGHET',
  LAYER_TITLE_INFILTRASJONSEVNE = 'LAYER.SUPERFICIAL_DEPOSITS_WMS.INFILTRASJONSEVNE',
  LAYER_TITLE_GRUNNVANNSPOTENSIALE = 'LAYER.SUPERFICIAL_DEPOSITS_WMS.GRUNNVANNSPOTENSIALE',
  LAYER_TITLE_SKREDMATERIALE_NORGE = 'LAYER.SUPERFICIAL_DEPOSITS_WMS.SKREDMATERIALE_NORGE',
  LAYER_TITLE_SKREDMATERIALE_N1000_WEB = 'LAYER.SUPERFICIAL_DEPOSITS_WMS.SKREDMATERIALE_N1000_WEB',
  LAYER_TITLE_SKREDMATERIALE_N1000 = 'LAYER.SUPERFICIAL_DEPOSITS_WMS.SKREDMATERIALE_N1000',
  LAYER_TITLE_SKREDMATERIALE = 'LAYER.SUPERFICIAL_DEPOSITS_WMS.SKREDMATERIALE',
  LAYER_TITLE_GRUNNFORURENSNING_2_WMS = 'LAYER.POLLUTION_WMS.GRUNNFORURENSNING_2_WMS',
  LAYER_TITLE_FORURENSET_OMRADE_TILSTAND = 'LAYER.POLLUTION_WMS.FORURENSET_OMRADE_TILSTAND',
  LAYER_TITLE_FORURENSET_OMRADE_TILSTAND_PKT = 'LAYER.POLLUTION_WMS.FORURENSET_OMRADE_TILSTAND_PKT',
  LAYER_TITLE_FORURENSET_OMRADE = 'LAYER.POLLUTION_WMS.FORURENSET_OMRADE',
  LAYER_TITLE_FORURENSET_OMRADE_PKT = 'LAYER.POLLUTION_WMS.FORURENSET_OMRADE_PKT',
  LAYER_TITLE_MINERALLUTTAK_WMS = 'LAYER.MINERAL_EXTRACTION_WMS.MINERALLUTTAK_WMS',
  LAYER_TITLE_OFFENTLIG_UTTAKSREGISTER = 'LAYER.MINERAL_EXTRACTION_WMS.OFFENTLIG_UTTAKSREGISTER',
  LAYER_GRAVEL_AND_HARD_ROCK_AGGREGATE_WMS_OPTIONS_GRAVEL_RESOURCES = 'LAYER.GRAVEL_AND_HARD_ROCK_AGGREGATE_WMS.OPTIONS.GRAVEL_RESOURCES',
  LAYER_GRAVEL_AND_HARD_ROCK_AGGREGATE_WMS_OPTIONS_RUBBLE_RESOURCES = 'LAYER.GRAVEL_AND_HARD_ROCK_AGGREGATE_WMS.OPTIONS.RUBBLE_RESOURCES',
  LAYER_GRAVEL_AND_HARD_ROCK_AGGREGATE_WMS_OPTIONS_ROCKFALL_RESOURCES = 'LAYER.GRAVEL_AND_HARD_ROCK_AGGREGATE_WMS.OPTIONS.ROCKFALL_RESOURCES',
  LAYER_GRAVEL_AND_HARD_ROCK_AGGREGATE_WMS_OPTIONS_MASS_CEILING = 'LAYER.GRAVEL_AND_HARD_ROCK_AGGREGATE_WMS.OPTIONS.MASS_CEILING',
  LAYER_GRAVEL_AND_HARD_ROCK_AGGREGATE_WMS_OPTIONS_CRUSHED_STONE = 'LAYER.GRAVEL_AND_HARD_ROCK_AGGREGATE_WMS.OPTIONS.CRUSHED_STONE',
  LAYER_GRAVEL_AND_HARD_ROCK_AGGREGATE_WMS_OPTIONS_STONE_TIP_ROOF = 'LAYER.GRAVEL_AND_HARD_ROCK_AGGREGATE_WMS.OPTIONS.STONE_TIP_ROOF',
  LAYER_POLLUTION_WMS_OPTIONS_IMPACT_RATE = 'LAYER.POLLUTION_WMS.OPTIONS.IMPACT_RATE',
  LAYER_POLLUTION_WMS_OPTIONS_SUSPICION_OF_CONTAMINATION = 'LAYER.POLLUTION_WMS.OPTIONS.SUSPICION_OF_CONTAMINATION',
  LAYER_POLLUTION_WMS_OPTIONS_UNACCEPTABLE_POLLUTION = 'LAYER.POLLUTION_WMS.OPTIONS.UNACCEPTABLE_POLLUTION',
  LAYER_POLLUTION_WMS_OPTIONS_ACCEPTABLE_POLLUTION = 'LAYER.POLLUTION_WMS.OPTIONS.ACCEPTABLE_POLLUTION',
  LAYER_POLLUTION_WMS_OPTIONS_LITTLE_OR_NO_POLLUTION = 'LAYER.POLLUTION_WMS.OPTIONS.LITTLE_OR_NO_POLLUTION',
  LAYER_MINERAL_EXTRACTION_WMS_OPTIONS_OTHER_VALUES = 'LAYER.MINERAL_EXTRACTION_WMS.OPTIONS.OTHER_VALUES',
  LAYER_MINERAL_EXTRACTION_WMS_OPTIONS_DRIFT_STATUS_WITHDRAWAL_TYPE = 'LAYER.MINERAL_EXTRACTION_WMS.OPTIONS.DRIFT_STATUS_WITHDRAWAL_TYPE',
  LAYER_MINERAL_EXTRACTION_WMS_OPTIONS_DRIFT_CONSTRUCTION_MATERIAL = 'LAYER.MINERAL_EXTRACTION_WMS.OPTIONS.DRIFT_CONSTRUCTION_MATERIAL',
  LAYER_MINERAL_EXTRACTION_WMS_OPTIONS_DRIFT_INDUSTRIAL_MINERALS = 'LAYER.MINERAL_EXTRACTION_WMS.OPTIONS.DRIFT_INDUSTRIAL_MINERALS',
  LAYER_MINERAL_EXTRACTION_WMS_OPTIONS_DRIFT_METALLIC_MAIM = 'LAYER.MINERAL_EXTRACTION_WMS.OPTIONS.DRIFT_METALLIC_MAIM',
  LAYER_MINERAL_EXTRACTION_WMS_OPTIONS_DRIFT_NATURAL_STONE = 'LAYER.MINERAL_EXTRACTION_WMS.OPTIONS.DRIFT_NATURAL_STONE',
  LAYER_MINERAL_EXTRACTION_WMS_OPTIONS_DRIFT_UNDEFINED = 'LAYER.MINERAL_EXTRACTION_WMS.OPTIONS.DRIFT_UNDEFINED',
  LAYER_MINERAL_EXTRACTION_WMS_OPTIONS_SECOND_CONSTRUCTION_MATERIAL = 'LAYER.MINERAL_EXTRACTION_WMS.OPTIONS.SECOND_CONSTRUCTION_MATERIAL',
  LAYER_MINERAL_EXTRACTION_WMS_OPTIONS_SECOND_INDUSTNIMINERALS = 'LAYER.MINERAL_EXTRACTION_WMS.OPTIONS.SECOND_INDUSTNIMINERALS',
  LAYER_MINERAL_EXTRACTION_WMS_OPTIONS_SECOND_METALLIC_MAIM = 'LAYER.MINERAL_EXTRACTION_WMS.OPTIONS.SECOND_METALLIC_MAIM',
  LAYER_MINERAL_EXTRACTION_WMS_OPTIONS_SECOND_NATURAL_STONE = 'LAYER.MINERAL_EXTRACTION_WMS.OPTIONS.SECOND_NATURAL_STONE',
  LAYER_MINERAL_EXTRACTION_WMS_OPTIONS_SECOND_UNDEFINED = 'LAYER.MINERAL_EXTRACTION_WMS.OPTIONS.SECOND_UNDEFINED',
  LAYER_MINERAL_EXTRACTION_WMS_OPTIONS_DISCONTINUED_CONSTRUCTION_MATERIAL = 'LAYER.MINERAL_EXTRACTION_WMS.OPTIONS.DISCONTINUED_CONSTRUCTION_MATERIAL',
  LAYER_MINERAL_EXTRACTION_WMS_OPTIONS_DISCONTINUED_INDUSTNIMINERALS = 'LAYER.MINERAL_EXTRACTION_WMS.OPTIONS.DISCONTINUED_INDUSTNIMINERALS',
  LAYER_MINERAL_EXTRACTION_WMS_OPTIONS_DISCONTINUED_METALLIC_MAIM = 'LAYER.MINERAL_EXTRACTION_WMS.OPTIONS.DISCONTINUED_METALLIC_MAIM',
  LAYER_MINERAL_EXTRACTION_WMS_OPTIONS_DISCONTINUED_NATURAL_STONE = 'LAYER.MINERAL_EXTRACTION_WMS.OPTIONS.DISCONTINUED_NATURAL_STONE',
  LAYER_MINERAL_EXTRACTION_WMS_OPTIONS_DISCONTINUED_UNDEFINED = 'LAYER.MINERAL_EXTRACTION_WMS.OPTIONS.DISCONTINUED_UNDEFINED',
  LAYER_SHOW_LEGEND = 'LAYER.SHOW_LEGEND',
  LAYER_HIDE_LEGEND = 'LAYER.HIDE_LEGEND',
  // FEEDBACK
  FEEDBACK_CREATE_TITLE = 'FEEDBACK.CREATE_TITLE',
  FEEDBACK_VISUAL_INSPECTION_TITLE = 'FEEDBACK.VISUAL_INSPECTION_TITLE',
  FEEDBACK_NO_SIGN_OF_POLLUTION_TITLE = 'FEEDBACK.NO_SIGN_OF_POLLUTION_TITLE',
  FEEDBACK_TAKE_PHOTO = 'FEEDBACK.TAKE_PHOTO',
  FEEDBACK_UPLOAD_LOGO = 'FEEDBACK.UPLOAD_LOGO',
  FEEDBACK_UPLOAD_IMAGE = 'FEEDBACK.UPLOAD_IMAGE',
  // TOC
  TOC_NOT_AVAILABLE = 'TOC.NOT_AVAILABLE',
  TOC_APPLY_FOR = 'TOC.APPLY_FOR',
  TOC_DATA = 'TOC.DATA',
  // PRIVACY
  PRIVACY_NOT_AVAILABLE = 'PRIVACY.NOT_AVAILABLE',
  PRIVACY_ABBREVIATION = 'PRIVACY.ABBREVIATION',
  // USE_OF_COOKIES
  USE_OF_COOKIES_NOT_AVAILABLE = 'USE_OF_COOKIES.NOT_AVAILABLE',
  USE_OF_COOKIES_ABBREVIATION = 'USE_OF_COOKIES.ABBREVIATION',
  // OCCURRENCE
  OCCURRENCE_MAIN_ROCK = 'OCCURRENCE.MAIN_ROCK',
  OCCURRENCE_MEANING = 'OCCURRENCE.MEANING',
  OCCURRENCE_WEBSITE = 'OCCURRENCE.WEBSITE',
  OCCURRENCE_CONTACT_PERSON = 'OCCURRENCE.CONTACT_PERSON',
  OCCURRENCE_ADDRESS = 'OCCURRENCE.ADDRESS',
  // USER
  USER_TITLE = 'USER.TITLE',
  USER_USERS_REGISTER = 'USER.USERS_REGISTER',
  USER_VERIFY = 'USER.VERIFY',
  USER_DEACTIVATE = 'USER.DEACTIVATE',
  USER_ACTIVATE = 'USER.ACTIVATE',
  USER_APPROVE = 'USER.APPROVE',
  USER_ROLE_ADMIN = 'USER.ROLE_ADMIN',
  USER_ROLE_USER = 'USER.ROLE_USER',
  USER_ROLE_COMPANY = 'USER.ROLE_COMPANY',
  USER_INVITE_TITLE = 'USER.INVITE_TITLE',
  USER_CONTACT_PERSON_TITLE = 'USER.CONTACT_PERSON_TITLE',
  USER_SEARCH_FIELD_PLACEHOLDER = 'USER.SEARCH_FIELD_PLACEHOLDER',
  // MASS
  MASS_RECYCLED = 'MASS.RECYCLED',
  MASS_NATURAL = 'MASS.NATURAL',
  MASS_CRUSHED = 'MASS.CRUSHED',
  MASS_ORIGIN = 'MASS.ORIGIN', // Newly added
  MASS_PRICE = 'MASS.PRICE', // Newly added
  MASS_DATE_RANGE_REQUIRED = 'MASS.DATE_RANGE_REQUIRED',
  MASS_CATEGORY = 'MASS.CATEGORY',
  // NOTIFICATION
  NOTIFICATION_NEW_OFFER = 'NOTIFICATION.NEW_OFFER',
  NOTIFICATION_NEW_RESERVATION = 'NOTIFICATION.NEW_RESERVATION',
  NOTIFICATION_MASS_EXPIRED = 'NOTIFICATION.MASS_EXPIRED',
  NOTIFICATION_MASS_COMPLETED = 'NOTIFICATION.MASS_COMPLETED',
  NOTIFICATION_NOTIFICATIONS = 'NOTIFICATION.NOTIFICATIONS',
  NOTIFICATION_TODAY = 'NOTIFICATION.TODAY',
  NOTIFICATION_YESTERDAY = 'NOTIFICATION.YESTERDAY',
  NOTIFICATION_NEW_DECLARATION_MESSAGE = 'NOTIFICATION.NEW_DECLARATION_MESSAGE',
  NOTIFICATION_CLEAR_NOTIFCATIONS = 'NOTIFICATION.CLEAR_NOTIFCATIONS',
  NOTIFICATION_EXPIRATION_DATE = 'NOTIFICATION.EXPIRATION_DATE',
  // FILE
  FILE_LIMIT = 'FILE.LIMIT',
  // IMAGE
  IMAGE_TRANSPARENCY_HELPTEXT = 'IMAGE.TRANSPARENCY_HELPTEXT',

  // DECLARATION
  DECLARATION_TITLE = 'DECLARATION.TITLE',
  DECLARATION_CUSTOMER_AND_CONTACT_PERSON = 'DECLARATION.CUSTOMER_AND_CONTACT_PERSON',
  DECLARATION_TRANSPORTATION_AND_QUANTITY = 'DECLARATION.TRANSPORTATION_AND_QUANTITY',
  DECLARATION_DEPOT_SPECIFICS = 'DECLARATION.DEPOT_SPECIFICS',
  DECLARATION_OFFER = 'DECLARATION.OFFER',
  DECLARATION_SIGNING = 'DECLARATION.SIGNING',
  DECLARATION_ADD_CUSTOMER = 'DECLARATION.ADD_CUSTOMER',
  DECLARATION_ADD_CONTACT_PERSON = 'DECLARATION.ADD_CONTACT_PERSON',
  DECLARATION_ADD_PROJECT = 'DECLARATION.ADD_PROJECT',
  DECLARATION_DELIVERY = 'DECLARATION.DELIVERY',
  DECLARATION_SINGLE_DELIVERY = 'DECLARATION.SINGLE_DELIVERY',
  DECLARATION_REGULAR_DELIVERY = 'DECLARATION.REGULAR_DELIVERY',
  DECLARATION_TON = 'DECLARATION.TON',
  DECLARATION_TRANSPORT_TYPE_AND_PACKAGING = 'DECLARATION.TRANSPORT_TYPE_AND_PACKAGING',
  DECLARATION_SELECT_PACKAGING_HELPERTEXT = 'DECLARATION.SELECT_PACKAGING_HELPERTEXT',
  DECLARATION_SELECT_TRANSPORT_TYPE_HELPERTEXT = 'DECLARATION.SELECT_TRANSPORT_TYPE_HELPERTEXT',
  DECLARATION_SELECT_CUSTOMER_HELPERTEXT = 'DECLARATION.SELECT_CUSTOMER_HELPERTEXT',
  DECLARATION_SELECT_CONTACT_PERSON_HELPERTEXT = 'DECLARATION.SELECT_CONTACT_PERSON_HELPERTEXT',
  DECLARATION_SELECT_PROJECT_HELPERTEXT = 'DECLARATION.SELECT_PROJECT_HELPERTEXT',
  DECLARATION_DANGEROUS_WASTE = 'DECLARATION.DANGEROUS_WASTE',
  DECLARATION_GENERAL_WASTE = 'DECLARATION.GENERAL_WASTE',
  DECLARATION_INERT_WASTE = 'DECLARATION.INERT_WASTE',
  DECLARATION_DELIVERED_TO_DEPOT_IN_CATEGORY = 'DECLARATION.DELIVERED_TO_DEPOT_IN_CATEGORY',
  DECLARATION_REASONING_FOR_THE_CHOICE_OF_WASTE_CATEGORY = 'DECLARATION.REASONING_FOR_THE_CHOICE_OF_WASTE_CATEGORY',
  DECLARATION_REASONING_FOR_WHY_THE_WASTE_HAS_NOT_BEEN_TESTED_HELPERTEXT = 'DECLARATION.REASONING_FOR_WHY_THE_WASTE_HAS_NOT_BEEN_TESTED_HELPERTEXT',
  DECLARATION_WASTE_CLASSIFICATION_NUMBER = 'DECLARATION.WASTE_CLASSIFICATION_NUMBER',
  DECLARATION_WASTE_TESTING = 'DECLARATION.WASTE_TESTING',
  DECLARATION_WASTE_CATEGORY = 'DECLARATION.WASTE_CATEGORY',
  DECLARATION_WASTE_CATEGORY_DANGEROUS_WASTE_INFO = 'DECLARATION.WASTE_CATEGORY_DANGEROUS_WASTE_INFO',
  DECLARATION_WASTE_CATEGORY_GENERAL_WASTE_INFO = 'DECLARATION.WASTE_CATEGORY_GENERAL_WASTE_INFO',
  DECLARATION_WASTE_CATEGORY_INERT_WASTE_INFO = 'DECLARATION.WASTE_CATEGORY_GENERAL_WASTE_INFO',
  DECLARATION_WASTE_PROPERTIES = 'DECLARATION.WASTE_PROPERTIES',
  DECLARATION_WASTE_TYPE = 'DECLARATION.WASTE_TYPE',
  DECLARATION_MATERIAL = 'DECLARATION.MATERIAL',
  DECLARATION_HANDLING = 'DECLARATION.HANDLING',
  DECLARATION_INDUSTRIAL = 'DECLARATION.INDUSTRIAL',
  DECLARATION_MUNICIPALITY = 'DECLARATION.MUNICIPALITY',
  DECLARATION_CONDITION_CLASS = 'DECLARATION.CONDITION_CLASS',
  DECLARATION_TURN_ON_IF_THERE_IS_AN_ANALYSIS_OF_THE_WASTE = 'DECLARATION.TURN_ON_IF_THERE_IS_AN_ANALYSIS_OF_THE_WASTE',
  DECLARATION_ANALYSIS_RESULTS = 'DECLARATION.ANALYSIS_RESULTS',
  DECLARATION_TURN_ON_IF_IT_CONTAINS_HAZARDOUS_SUBSTANCES = 'DECLARATION.TURN_ON_IF_IT_CONTAINS_HAZARDOUS_SUBSTANCES',
  DECLARATION_TURN_ON_IF_THE_WASTE_CONTAINS_ORGANIC_CARBON = 'DECLARATION.TURN_ON_IF_THE_WASTE_CONTAINS_ORGANIC_CARBON',
  DECLARATION_BIODEGRADABLE_CONTENT = 'DECLARATION.BIODEGRADABLE_CONTENT',
  DECLARATION_LOSS_ON_IGNITION = 'DECLARATION.LOSS_ON_IGNITION',
  DECLARATION_LEACHING_PROPERTIES = 'DECLARATION.LEACHING_PROPERTIES',
  DECLARATION_TURN_ON_IF_COLUMN_LEACHING_TEST_HAS_BEEN_PERFORMED = 'DECLARATION.TURN_ON_IF_COLUMN_LEACHING_TEST_HAS_BEEN_PERFORMED',
  DECLARATION_TURN_ON_IF_SIEVE_TEST_HAS_BEEN_PERFORMED = 'DECLARATION.TURN_ON_IF_SIEVE_TEST_HAS_BEEN_PERFORMED',
  DECLARATION_PHYSICAL_PROPERTIES = 'DECLARATION.PHYSICAL_PROPERTIES',
  DECLARATION_PRE_TREATMENT = 'DECLARATION.PRE_TREATMENT',
  DECLARATION_TURN_ON_IF_WASTE_NEEDS_PRE_TREATMENT_BEFORE_DISPOSAL = 'DECLARATION.TURN_ON_IF_WASTE_NEEDS_PRE_TREATMENT_BEFORE_DISPOSAL',
  DECLARATION_ODOR = 'DECLARATION.ODOR',
  DECLARATION_COLOR = 'DECLARATION.COLOR',
  DECLARATION_PRICE_PR_TON = 'DECLARATION.PRICE_PR_TON',
  DECLARATION_TOTAL_PRICE = 'DECLARATION.TOTAL_PRICE',
  DECLARATION_PRICE_OFFER = 'DECLARATION.PRICE_OFFER',
  DECLARATION_NAME_AND_LASTNAME = 'DECLARATION.NAME_AND_LASTNAME',
  DECLARATION_SIGNING_FIELD_HELPERTEXT = 'DECLARATION.SIGNING_FIELD_HELPERTEXT',
  DECLARATION_CHECK_YOURSELF_AS_A_CONTACT_PERSON = 'DECLARATION.CHECK_YOURSELF_AS_A_CONTACT_PERSON',
  DECLARATION_VIEW_DECLARATION = 'DECLARATION.VIEW_DECLARATION',
  DECLARATION_SUCCESSFULLY_SAVED = 'DECLARATION.SUCCESSFULLY_SAVED',
  DECLARATION_SEND_DECLARATION_TO = 'DECLARATION.SEND_DECLARATION_TO',
  DECLARATION_SAVE_CONFIRMATION = 'DECLARATION.SAVE_CONFIRMATION',
  DECLARATION_REJECT_CONFIRMATION = 'DECLARATION.REJECT_CONFIRMATION',
  DECLARATION_APPROVE_CONFIRMATION = 'DECLARATION.APPROVE_CONFIRMATION',
  DECLARATION_REQUEST_PRICE_CONFIRMATION = 'DECLARATION.REQUEST_PRICE_CONFIRMATION',
  DECLARATION_ACCEPT_PRICE_CONFIRMATION = 'DECLARATION.ACCEPT_PRICE_CONFIRMATION',
  DECLARATION_SIGN_CONFIRMATION = 'DECLARATION.SIGN_CONFIRMATION',
  DECLARATION_DIRECTLY_SIGN_WARNING = 'DECLARATION.DIRECTLY_SIGN_WARNING',
  DECLARATION_REJECT_REASON = 'DECLARATION.REJECT_REASON',
  DECLARATION_PRICE_REQUEST_REASON = 'DECLARATION.PRICE_REQUEST_REASON',
  DECLARATION_CUSTOMER_SIGNATURE = 'DECLARATION.CUSTOMER_SIGNATURE',
  DECLARATION_TABLE_COLUMNS_CUSTOMER = 'DECLARATION.TABLE_COLUMNS.CUSTOMER',
  DECLARATION_TABLE_COLUMNS_PROJECT = 'DECLARATION.TABLE_COLUMNS.PROJECT',
  DECLARATION_TABLE_COLUMNS_WASTE_TYPE = 'DECLARATION.TABLE_COLUMNS.WASTE_TYPE',
  DECLARATION_TABLE_COLUMNS_AMOUNT = 'DECLARATION.TABLE_COLUMNS.AMOUNT',
  DECLARATION_TABLE_COLUMNS_SIGNED_DATE = 'DECLARATION.TABLE_COLUMNS.SIGNED_DATE',
  DECLARATION_TABLE_COLUMNS_STATUS = 'DECLARATION.TABLE_COLUMNS.STATUS',
  DECLARATION_TABLE_COLUMNS_ACTIONS = 'DECLARATION.TABLE_COLUMNS.ACTIONS',
  DECLARATION_TABLE_ROWS_PER_PAGE = 'DECLARATION.TABLE_ROWS_PER_PAGE',
  DECLARATION_TABLE_DISPLAYED_ROWS = 'DECLARATION.TABLE_DISPLAYED_ROWS',
  DECLARATION_OPEN_LOG_COMMENT_SECTION = 'DECLARATION.OPEN_LOG_COMMENT_SECTION',
  DECLARATION_CLOSE_LOG_COMMENT_SECTION = 'DECLARATION.CLOSE_LOG_COMMENT_SECTION',
  DECLARATION_LOG = 'DECLARATION.LOG',
  DECLARATION_COMMENTS = 'DECLARATION.COMMENTS',
  DECLARATION_COMMENTS_MESSAGE_PLACEHOLDER = 'DECLARATION.COMMENTS_MESSAGE_PLACEHOLDER',
  DECLARATION_ADD_NEW_DEPOT_SPECIFIC = 'DECLARATION.ADD_NEW_DEPOT_SPECIFIC',
  DECLARATION_PHYSICAL_PROPERTY_POWDER = 'DECLARATION.PHYSICAL_PROPERTY.POWDER',
  DECLARATION_PHYSICAL_PROPERTY_LIQUID = 'DECLARATION.PHYSICAL_PROPERTY.LIQUID',
  DECLARATION_PHYSICAL_PROPERTY_LARGE_OBJECT = 'DECLARATION.PHYSICAL_PROPERTY.LARGE_OBJECT',
  DECLARATION_PHYSICAL_PROPERTY_COMPOSITE = 'DECLARATION.PHYSICAL_PROPERTY.COMPOSITE',
  DECLARATION_PHYSICAL_PROPERTY_UNIFORM = 'DECLARATION.PHYSICAL_PROPERTY.UNIFORM',
  DECLARATION_PHYSICAL_PROPERTY_OTHER = 'DECLARATION.PHYSICAL_PROPERTY.OTHER',
  DECLARATION_PRETREATMENT_METHOD_SORTING_FACILITY = 'DECLARATION.PRETREATMENT_METHOD.SORTING_FACILITY',
  DECLARATION_PRETREATMENT_METHOD_BIOLOGICAL = 'DECLARATION.PRETREATMENT_METHOD.BIOLOGICAL',
  DECLARATION_PRETREATMENT_METHOD_INCINERATION = 'DECLARATION.PRETREATMENT_METHOD.INCINERATION',
  DECLARATION_PRETREATMENT_METHOD_GRINDIND = 'DECLARATION.PRETREATMENT_METHOD.GRINDIND',
  DECLARATION_PRETREATMENT_METHOD_NONE = 'DECLARATION.PRETREATMENT_METHOD.NONE',
  DECLARATION_PRETREATMENT_METHOD_OTHER = 'DECLARATION.PRETREATMENT_METHOD.OTHER',
  DECLARATION_PACKAGING_TYPE_GLASS = 'DECLARATION.PACKAGING_TYPE.GLASS',
  DECLARATION_PACKAGING_TYPE_ALUMINIUM = 'DECLARATION.PACKAGING_TYPE.ALUMINIUM',
  DECLARATION_PACKAGING_TYPE_STEEL = 'DECLARATION.PACKAGING_TYPE.STEEL',
  DECLARATION_PACKAGING_TYPE_PAPER = 'DECLARATION.PACKAGING_TYPE.PAPER',
  DECLARATION_PACKAGING_TYPE_WOOD = 'DECLARATION.PACKAGING_TYPE.WOOD',
  DECLARATION_PACKAGING_TYPE_PLASTIC = 'DECLARATION.PACKAGING_TYPE.PLASTIC',
  DECLARATION_TRANSPORT_INFO = 'DECLARATION.TRANSPORT_INFO',
  DECLARATION_EAL_CODE_INFO = 'DECLARATION.EAL_CODE_INFO',
  DECLARATION_WASTE_TYPE_NUMBER_INFO = 'DECLARATION.WASTE_TYPE_NUMBER_INFO',
  DECLARATION_CONDITION_CLASS_INFO = 'DECLARATION.CONDITION_CLASS_INFO',
  DECLARATION_HAZARDOUS_SUBSTANCE_INFO = 'DECLARATION.HAZARDOUS_SUBSTANCE_INFO',
  DECLARATION_PHYSICAL_PROPERTIES_INFO = 'DECLARATION.PHYSICAL_PROPERTIES_INFO',
  DECLARATION_PRETREATMENT_INFO = 'DECLARATION.PRETREATMENT_INFO',
  DECLARATION_PRETREATMENT_BEFORE_DISPOSAL_INFO = 'DECLARATION.PRETREATMENT_BEFORE_DISPOSAL_INFO',
  DECLARATION_TOTAL_PRICE_HELPER_TEXT = 'DECLARATION.TOTAL_PRICE_HELPER_TEXT',
  DECLARATION_SEND_DECLARATION_TO_DEPOT = 'DECLARATION.SEND_DECLARATION_TO_DEPOT',
  // Customer
  CUSTOMER_CREATE = 'CUSTOMER.CREATE',
  CUSTOMER_EDIT = 'CUSTOMER.EDIT',
  CUSTOMER_TITLE = 'CUSTOMER.TITLE',
  CUSTOMER_TITLE_PLURAL = 'CUSTOMER.TITLE_PLURAL',
  CUSTOMER_NO_UNREGISTERED_COMPANY = 'CUSTOMER.NO_UNREGISTERED_COMPANY',
  CUSTOMER_FIRST_VISIT = 'CUSTOMER.FIRST_VISIT',
  CUSTOMER_VISITED_AS_GUEST = 'CUSTOMER.VISITED_AS_GUEST',
  CUSTOMER_SEARCH_FIELD_PLACEHOLDER = 'CUSTOMER.SEARCH_FIELD_PLACEHOLDER',
  // Reception control
  RECEPTION_CONTROL_TITLE = 'RECEPTION_CONTROL.TITLE',
  RECEPTION_CONTROL_TITLE_PLURAL = 'RECEPTION_CONTROL.TITLE_PLURAL',
  RECEPTION_CONTROL_NEW_MASS = 'RECEPTION_CONTROL.NEW_MASS',
  RECEPTION_CONTROL_EDIT_MASS = 'RECEPTION_CONTROL.EDIT_MASS',
  RECEPTION_CONTROL_VISUALLY_INSPECTED_AND_APPROVED = 'RECEPTION_CONTROL.VISUALLY_INSPECTED_AND_APPROVED',
  RECEPTION_CONTROL_TEST_TAKEN = 'RECEPTION_CONTROL.TEST_TAKEN',
  RECEPTION_CONTROL_SUCCESSFULLY_SAVED = 'RECEPTION_CONTROL.SUCCESSFULLY_SAVED',
  RECEPTION_CONTROL_EDIT_COLLIE = 'RECEPTION_CONTROL.EDIT_COLLIE',
  RECEPTION_CONTROL_REGISTER_NEW_COLLIE = 'RECEPTION_CONTROL.REGISTER_NEW_COLLIE',
  RECEPTION_CONTROL_FULLY_DELIVERY_NOTICE = 'RECEPTION_CONTROL.FULLY_DELIVERY_NOTICE',
  RECEPTION_CONTROL_PARTIALLY_DELIVERY_NOTICE = 'RECEPTION_CONTROL.PARTIALLY_DELIVERY_NOTICE',
  RECEPTION_CONTROL_SAVE_AND_COMPLETED_NOTICE = 'RECEPTION_CONTROL.SAVE_AND_COMPLETED_NOTICE',
  RECEPTION_CONTROL_SAVE_NOTICE = 'RECEPTION_CONTROL.SAVE_NOTICE',
  RECEPTION_CONTROL_VIEW_RECEPTION_CONTROL = 'RECEPTION_CONTROL.VIEW_RECEPTION_CONTROL',
  RECEPTION_CONTROL_MAKE_RECEPTION_CONTROL = 'RECEPTION_CONTROL.MAKE_RECEPTION_CONTROL',
  RECEPTION_CONTROL_DOWNLOAD_DECLARATION_SUMMARY = 'RECEPTION_CONTROL.DOWNLOAD_DECLARATION_SUMMARY',
  RECEPTION_CONTROL_DOWNLOAD_MULTIPLE_DECLARATION_SUMMARY = 'RECEPTION_CONTROL.DOWNLOAD_MULTIPLE_DECLARATION_SUMMARY',
  // Change log
  LOG_TABLE_DECLARATION = 'LOG.TABLE.DECLARATION',
  LOG_DECLARATION_CUSTOMER = 'LOG.DECLARATION.CUSTOMER',
  LOG_DECLARATION_CONTACT_PERSON = 'LOG.DECLARATION.CONTACT_PERSON',
  LOG_DECLARATION_PROJECT = 'LOG.DECLARATION.PROJECT',
  LOG_DECLARATION_DELIVERY_TYPE = 'LOG.DECLARATION.DELIVERY_TYPE',
  LOG_DECLARATION_VOLUME = 'LOG.DECLARATION.VOLUME',
  LOG_DECLARATION_TRANSPORT_TYPE = 'LOG.DECLARATION.TRANSPORT_TYPE',
  LOG_DECLARATION_PACKAGING = 'LOG.DECLARATION.PACKAGING',
  LOG_DECLARATION_WASTE_CATEGORY_TYPE = 'LOG.DECLARATION.WASTE_CATEGORY_TYPE',
  LOG_DECLARATION_WASTE_CHOICE_REASON = 'LOG.DECLARATION.WASTE_CHOICE_REASON',
  LOG_DECLARATION_EAL_CODES_STRINGIFIED = 'LOG.DECLARATION.EAL_CODES_STRINGIFIED',
  LOG_DECLARATION_HANDLING = 'LOG.DECLARATION.HANDLING',
  LOG_DECLARATION_INDUSTRIAL = 'LOG.DECLARATION.INDUSTRIAL',
  LOG_DECLARATION_MATERIAL = 'LOG.DECLARATION.MATERIAL',
  LOG_DECLARATION_MUNICIPALITY = 'LOG.DECLARATION.MUNICIPALITY',
  LOG_DECLARATION_CONDITION_CLASS = 'LOG.DECLARATION.CONDITION_CLASS',
  LOG_DECLARATION_WASTE_ANALYZING = 'LOG.DECLARATION.WASTE_ANALYZING',
  LOG_DECLARATION_ANALYSIS_FILE = 'LOG.DECLARATION.ANALYSIS_FILE',
  LOG_DECLARATION_ANALYSIS_FILES = 'LOG.DECLARATION.ANALYSIS_FILES',
  LOG_DECLARATION_CONTAINS_DANGEROUS_SUBSTANCE = 'LOG.DECLARATION.CONTAINS_DANGEROUS_SUBSTANCE',
  LOG_DECLARATION_CONTAINS_ORGANIC_CARBON = 'LOG.DECLARATION.CONTAINS_ORGANIC_CARBON',
  LOG_DECLARATION_TOC_PERCENTAGE = 'LOG.DECLARATION.TOC_PERCENTAGE',
  LOG_DECLARATION_HEAT_LOSS_PERCENTAGE = 'LOG.DECLARATION.HEAT_LOSS_PERCENTAGE',
  LOG_DECLARATION_LEACHING_COLUMN_TEST = 'LOG.DECLARATION.LEACHING_COLUMN_TEST',
  LOG_DECLARATION_LEACHING_SHAKE_TEST = 'LOG.DECLARATION.LEACHING_SHAKE_TEST',
  LOG_DECLARATION_WASTE_TYPE_STRINGIFIED = 'LOG.DECLARATION.WASTE_TYPE_STRINGIFIED',
  LOG_DECLARATION_PHYSICAL_PROPERTIES = 'LOG.DECLARATION.PHYSICAL_PROPERTIES',
  LOG_DECLARATION_PRE_TREATMENT = 'LOG.DECLARATION.PRE_TREATMENT',
  LOG_DECLARATION_REQUIRE_PRE_TREATMENT = 'LOG.DECLARATION.REQUIRE_PRE_TREATMENT',
  LOG_DECLARATION_SMELL = 'LOG.DECLARATION.SMELL',
  LOG_DECLARATION_COLOR = 'LOG.DECLARATION.COLOR',
  LOG_DECLARATION_DEPOT_SPECIFIC_ANSWERS = 'LOG.DECLARATION.DEPOT_SPECIFIC_ANSWERS',
  LOG_DECLARATION_PRICE_PER_VOLUME = 'LOG.DECLARATION.PRICE_PER_VOLUME',
  LOG_DECLARATION_SIGNED_PERSON_NAME = 'LOG.DECLARATION.SIGNED_PERSON_NAME',
  LOG_DECLARATION_SIGNED_DATE = 'LOG.DECLARATION.SIGNED_DATE',
  LOG_DECLARATION_CONTENT_REJECTION_REASON = 'LOG.DECLARATION.CONTENT_REJECTION_REASON',
  LOG_DECLARATION_PRICE_REQUEST_REASON = 'LOG.DECLARATION.PRICE_REQUEST_REASON',
  LOG_DECLARATION_DEPOT = 'LOG.DECLARATION.DEPOT',
  LOG_DECLARATION_STATUS = 'LOG.DECLARATION.STATUS',
  LOG_DECLARATION_DYNAMIC_FORM = 'LOG.DECLARATION.DYNAMIC_FORM',
  LOG_DECLARATION_SUBMIT_ACTION_SENT = 'LOG.DECLARATION.SUBMIT_ACTION.SENT',
  LOG_DECLARATION_SUBMIT_ACTION_REJECTED = 'LOG.DECLARATION.SUBMIT_ACTION.REJECTED',
  LOG_DECLARATION_SUBMIT_ACTION_APPROVED = 'LOG.DECLARATION.SUBMIT_ACTION.APPROVED',
  LOG_DECLARATION_SUBMIT_ACTION_PRICING = 'LOG.DECLARATION.SUBMIT_ACTION.PRICING',
  LOG_DECLARATION_SUBMIT_ACTION_ACCEPTED = 'LOG.DECLARATION.SUBMIT_ACTION.ACCEPTED',
  LOG_DECLARATION_SUBMIT_ACTION_SIGNED = 'LOG.DECLARATION.SUBMIT_ACTION.SIGNED',
  // Save template
  SAVE_TEMPLATE_INPUT_LABEL = 'SAVE_TEMPLATE.INPUT_LABEL',
  SAVE_TEMPLATE_DIALOG_TITLE = 'SAVE_TEMPLATE.DIALOG.TITLE',
  SAVE_TEMPLATE_DIALOG_DESCRIPTION = 'SAVE_TEMPLATE.DIALOG.DESCRIPTION',
  SAVE_TEMPLATE_TABLE_HEADER_LABEL = 'SAVE_TEMPLATE.TABLE.HEADER_LABEL',
  SAVE_TEMPLATE_TABLE_HEADER_TYPE = 'SAVE_TEMPLATE.TABLE.HEADER_TYPE',
  SAVE_TEMPLATE_TABLE_CAPTION = 'SAVE_TEMPLATE.TABLE.CAPTION',
  SAVE_TEMPLATE_NAME_EXISTS_HELPER = 'SAVE_TEMPLATE.NAME_EXISTS_HELPER',
  // Association
  ASSOCIATION_TITLE = 'ASSOCIATION.TITLE',
  ASSOCIATION_NAME = 'ASSOCIATION.NAME',
  ASSOCIATION_DESCRIPTION = 'ASSOCIATION.DESCRIPTION',
  ASSOCIATION_CREATE_ASSOCIATION = 'ASSOCIATION.CREATE_ASSOCIATION',
  ASSOCIATION_USERS_COMPANIES = 'ASSOCIATION.USERS_COMPANIES',
  // Status
  STATUS_PROJECT_PLANNED = 'STATUS.PROJECT.PLANNED',
  STATUS_PROJECT_ACTIVE = 'STATUS.PROJECT.ACTIVE',
  STATUS_PROJECT_COMPLETED = 'STATUS.PROJECT.COMPLETED',
  STATUS_PROJECT_CANCELLED = 'STATUS.PROJECT.CANCELLED',
  STATUS_PROJECT_CLOSED = 'STATUS.PROJECT.CLOSED',
  STATUS_ACTIVITIES_RESULT_EXPIRED = 'STATUS.ACTIVITIES_RESULT.EXPIRED',
  STATUS_ACTIVITIES_RESULT_ACTIVE = 'STATUS.ACTIVITIES_RESULT.ACTIVE',
  STATUS_ACTIVITIES_RESULT_RESERVED = 'STATUS.ACTIVITIES_RESULT.RESERVED',
  STATUS_ACTIVITIES_RESULT_COMPLETED = 'STATUS.ACTIVITIES_RESULT.COMPLETED',
  STATUS_ACTIVITIES_RESULT_PRE_CREATED = 'STATUS.ACTIVITIES_RESULT.PRE_CREATED',
  STATUS_ACTIVITIES_RESULT_CANCELLED = 'STATUS.ACTIVITIES_RESULT.CANCELLED',
  STATUS_REQUEST_EXPIRED = 'STATUS.REQUEST.EXPIRED',
  STATUS_REQUEST_ACTIVE = 'STATUS.REQUEST.ACTIVE',
  STATUS_REQUEST_RESERVED = 'STATUS.REQUEST.RESERVED',
  STATUS_REQUEST_COMPLETED = 'STATUS.REQUEST.COMPLETED',
  STATUS_REQUEST_CANCELLED = 'STATUS.REQUEST.CANCELLED',
  STATUS_SUPPLY_EXPIRED = 'STATUS.SUPPLY.EXPIRED',
  STATUS_SUPPLY_ACTIVE = 'STATUS.SUPPLY.ACTIVE',
  STATUS_SUPPLY_RESERVED = 'STATUS.SUPPLY.RESERVED',
  STATUS_SUPPLY_COMPLETED = 'STATUS.SUPPLY.COMPLETED',
  STATUS_SUPPLY_CANCELLED = 'STATUS.SUPPLY.CANCELLED',
  STATUS_SUPPLY = 'STATUS.SUPPLY_AND_DEMAND.SUPPLY',
  STATUS_DEMAND = 'STATUS.SUPPLY_AND_DEMAND.DEMAND',
  STATUS_SUPPLY_AND_DEMAND = 'STATUS.SUPPLY_AND_DEMAND.SUPPLY_AND_DEMAND',
  STATUS_DECLARATIONS_DRAFT = 'STATUS.DECLARATIONS.DRAFT',
  STATUS_DECLARATIONS_SENT = 'STATUS.DECLARATIONS.SENT',
  STATUS_DECLARATIONS_REQUESTED = 'STATUS.DECLARATIONS.REQUESTED',
  STATUS_DECLARATIONS_SIGNED = 'STATUS.DECLARATIONS.SIGNED',
}
