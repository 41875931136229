import { ActivitiesResultStatusesType } from '@/dto/mass/activities/ActivitiesResult.dto';
import { RegisterRequestDto } from '@/dto/mass/request/RegisterRequest.dto';
import { Request } from '@/dto/mass/request/Request.dto';
import { CreateUpdateTransaction } from '@/dto/mass/reservation/Reservation.dto';
import { Supply } from '@/dto/mass/supply/Supply.dto';
import {
  ACTIVITIES_RESULT_STATUS,
  ACTIVITIES_RESULT_TYPE,
  REGISTERED_ACTIVITIES,
  TRANSACTION_ACTIVITIES,
  MASS_TRANSACTION_TYPES,
  TRANSPORT,
  MASS_ORIGIN_TYPES,
} from '@/enums/mass.enum';
import { RegisterMassFormValidation } from '@/validations/mass.validation';
import { addDays } from '@utils/date.utils';
import { INIT_LOCATION } from './location.constants';
import {
  INIT_SETTINGS_HUB,
  INIT_SETTINGS_MATERIAL,
} from './settings.containts';
import { I18N } from '@enums/i18n.enum';
import { InfoShareMass } from '@dto/mass/share/InfoShareMass.dto';
export const I18N_MASS_PREFIX = 'MASS_';

export const INIT_SUPPLY: Supply = {
  categoryId: null,
  category: INIT_SETTINGS_MATERIAL,
  materialName: '',
  sizeFrom: 0,
  sizeTo: 0,
  volume: null,
  isOfferTransport: false,
  price: null,
  location: INIT_LOCATION,
  requestBeginPeriod: new Date(),
  requestEndPeriod: addDays(new Date(), 30),
  description: '',
  hubId: null,
  hub: INIT_SETTINGS_HUB,
  status: ACTIVITIES_RESULT_STATUS.EXPIRED,
  losAngelesValue: 0,
  microDeval: 0,
  origin: MASS_ORIGIN_TYPES.RECYCLED,
  projectId: null,
  declaration: '',
  userId: null,
  isCeApproved: false,
  isEpdAvailable: false,
  listFile: [],
  listFileDeleted: [],
  isPublic: true,
};

export const INIT_REGISTER_REQUEST: RegisterRequestDto = {
  categoryId: null,
  materialName: '',
  volume: null,
  location: INIT_LOCATION,
  requestBeginPeriod: new Date(),
  requestEndPeriod: new Date(),
  description: '',
  hubId: null,
  projectId: null,
  isCeApproved: false,
  isEpdAvailable: false,
  isPublic: true,
};

export const INIT_REQUEST: Request = {
  categoryId: null,
  category: INIT_SETTINGS_MATERIAL,
  materialName: '',
  sizeFrom: 0,
  sizeTo: 0,
  volume: null,
  isOfferTransport: false,
  location: INIT_LOCATION,
  requestBeginPeriod: new Date(),
  requestEndPeriod: addDays(new Date(), 30),
  description: '',
  status: ACTIVITIES_RESULT_STATUS.EXPIRED,
  hubId: null,
  hub: INIT_SETTINGS_HUB,
  losAngelesValue: 0,
  microDeval: 0,
  origin: MASS_ORIGIN_TYPES.RECYCLED,
  projectId: null,
  price: null,
  userId: null,
  isCeApproved: false,
  isEpdAvailable: false,
  listFile: [],
  listFileDeleted: [],
  isPublic: true,
};

export enum REGISTER_TYPE {
  SUPPLY = 'SUPPLY',
  REQUEST = 'REQUEST',
}

export const INIT_TRANSACTION: CreateUpdateTransaction = {
  volume: 0,
  location: INIT_LOCATION,
  transportDate: new Date(),
  transport: TRANSPORT.ARRANGE,
  massId: 0,
  transactionType: MASS_TRANSACTION_TYPES.OFFER,
};

export const INIT_TRANSACTION_FORM_ERROR: Record<string, boolean> = {
  volume: false,
  transportDate: false,
  // comment: false,
};

export const INIT_REGISTER_REQUEST_FORM_ERROR: Record<string, boolean> = {
  categoryId: false,
  materialName: false,
  volume: false,
  location: false,
  hubId: false,
  // description: false,
  availablePeriod: false,
};

export const INIT_REGISTER_REQUEST_FORM_VALIDATION: RegisterMassFormValidation =
  {
    errorForm: INIT_REGISTER_REQUEST_FORM_ERROR,
    isError: false,
  };

export const INIT_REGISTER_SUPPLY_FORM_ERROR: Record<string, boolean> = {
  categoryId: false,
  materialName: false,
  volume: false,
  location: false,
  hubId: false,
  // description: false,
  price: false,
  availablePeriod: false,
};

export const INIT_REGISTER_SUPPLY_FORM_VALIDATION: RegisterMassFormValidation =
  {
    errorForm: INIT_REGISTER_SUPPLY_FORM_ERROR,
    isError: false,
  };

export const INIT_SHARE_MASS_FORM: InfoShareMass = {
  email: '',
  massLink: '',
};

export const INIT_SHARE_MASS_FORM_ERROR: Record<string, boolean> = {
  email: false,
  massLink: false,
};

export const INIT_SHARE_MASS_FORM_VALIDATION: RegisterMassFormValidation = {
  errorForm: INIT_SHARE_MASS_FORM_ERROR,
  isError: false,
};

export const ActivitiesResultStatuses = [
  {
    key: ACTIVITIES_RESULT_STATUS.ACTIVE,
    label: 'Active',
    backgroundColor: 'info.background',
    labelColor: 'info.content',
  },
  {
    key: ACTIVITIES_RESULT_STATUS.EXPIRED,
    label: 'Expired',
    backgroundColor: 'action.disabledBackground',
    labelColor: 'text.medium',
  },
  {
    key: ACTIVITIES_RESULT_STATUS.RESERVED,
    label: 'Reserved',
    backgroundColor: 'warning.background',
    labelColor: 'warning.content',
  },
  {
    key: ACTIVITIES_RESULT_STATUS.COMPLETED,
    label: 'Completed',
    backgroundColor: 'success.background',
    labelColor: 'success.content',
  },
  {
    key: ACTIVITIES_RESULT_STATUS.ACTIVE,
    label: 'Active',
    backgroundColor: 'info.background',
    labelColor: 'info.content',
  },
  {
    key: ACTIVITIES_RESULT_STATUS.PRE_CREATED,
    label: 'Pre created',
    backgroundColor: 'action.disabledBackground',
    labelColor: 'text.medium',
  },
  {
    key: ACTIVITIES_RESULT_STATUS.CANCELLED,
    label: 'Cancelled',
    backgroundColor: 'action.disabledBackground',
    labelColor: 'text.medium',
  },
];

const INIT_ACTIVITIES_RESULT_STATUS = {
  key: ACTIVITIES_RESULT_STATUS.CANCELLED,
  label: 'Cancelled',
  backgroundColor: 'action.disabledBackground',
  labelColor: 'text.medium',
};

export const getActivitiesResultStatusesTranslation = (t: any) => {
  return [
    {
      key: ACTIVITIES_RESULT_STATUS.ACTIVE,
      label: t(I18N.STATUS_ACTIVITIES_RESULT_ACTIVE),
      backgroundColor: 'info.background',
      labelColor: 'info.content',
    },
    {
      key: ACTIVITIES_RESULT_STATUS.EXPIRED,
      label: t(I18N.STATUS_ACTIVITIES_RESULT_EXPIRED),
      backgroundColor: 'action.disabledBackground',
      labelColor: 'text.medium',
    },
    {
      key: ACTIVITIES_RESULT_STATUS.RESERVED,
      label: t(I18N.STATUS_ACTIVITIES_RESULT_RESERVED),
      backgroundColor: 'warning.background',
      labelColor: 'warning.content',
    },
    {
      key: ACTIVITIES_RESULT_STATUS.COMPLETED,
      label: t(I18N.STATUS_ACTIVITIES_RESULT_COMPLETED),
      backgroundColor: 'success.background',
      labelColor: 'success.content',
    },
    {
      key: ACTIVITIES_RESULT_STATUS.PRE_CREATED,
      label: t(I18N.STATUS_ACTIVITIES_RESULT_PRE_CREATED),
      backgroundColor: 'action.disabledBackground',
      labelColor: 'text.medium',
    },
    {
      key: ACTIVITIES_RESULT_STATUS.CANCELLED,
      label: t(I18N.STATUS_ACTIVITIES_RESULT_CANCELLED),
      backgroundColor: 'action.disabledBackground',
      labelColor: 'text.medium',
    },
  ];
};

const getInitActivitiesResultStatusTranslation = (t: any) => {
  return {
    key: ACTIVITIES_RESULT_STATUS.CANCELLED,
    label: t(I18N.STATUS_ACTIVITIES_RESULT_CANCELLED),
    backgroundColor: 'action.disabledBackground',
    labelColor: 'text.medium',
  };
};

export const getActivitiesResultStatus = (
  status: ACTIVITIES_RESULT_STATUS,
  t: any
): ActivitiesResultStatusesType => {
  const activitiesResultStatus = getActivitiesResultStatusesTranslation(t);
  return (
    activitiesResultStatus.find((x) => x.key === status) ??
    getInitActivitiesResultStatusTranslation(t)
  );
};

export const ActivitiesResultType = [
  {
    key: ACTIVITIES_RESULT_TYPE.SUPPLY,
    label: 'FILTER.SUPPLY',
  },
  {
    key: ACTIVITIES_RESULT_TYPE.REQUEST,
    label: 'FILTER.REQUEST',
  },
  {
    key: ACTIVITIES_RESULT_TYPE.RESERVATION,
    label: I18N.TITLE_RESERVATION,
  },
  {
    key: ACTIVITIES_RESULT_TYPE.OFFER,
    label: I18N.TITLE_OFFER,
  },
  {
    key: ACTIVITIES_RESULT_TYPE.ALL,
    label: I18N.BUTTONS_ALL,
  },
];

export const getActivitiesResultType = (
  type: ACTIVITIES_RESULT_TYPE,
  t?: any
): string => {
  const label = ActivitiesResultType.find((x) => x.key === type)?.label ?? '';
  return t ? t(label) : label;
};

const RegisterdedActivities = [
  {
    key: REGISTERED_ACTIVITIES.ALL,
    label: 'All',
  },
  {
    key: REGISTERED_ACTIVITIES.SUPPLY,
    label: 'Supply',
  },
  {
    key: REGISTERED_ACTIVITIES.REQUEST,
    label: 'Request',
  },
];

export const getLabelRegisterdedActivities = (
  filter: REGISTERED_ACTIVITIES
): string => {
  return RegisterdedActivities.find((x) => x.key === filter)?.label ?? '';
};

const TransactionActivities = [
  {
    key: TRANSACTION_ACTIVITIES.ALL,
    label: 'All',
  },
  {
    key: TRANSACTION_ACTIVITIES.RESERVATION,
    label: 'Reservation',
  },
  {
    key: TRANSACTION_ACTIVITIES.OFFER,
    label: 'Offer',
  },
];

export const getLabelTransactionActivities = (
  filter: TRANSACTION_ACTIVITIES
): string => {
  return TransactionActivities.find((x) => x.key === filter)?.label ?? '';
};
